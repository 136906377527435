import {Component, Input, OnInit} from '@angular/core';
import {Appuser_Users_Objects, User} from "../../../../../../../database-models";
import {AppuserEditComponent} from "../../appuser-edit/appuser-edit.component";
import {ApiService} from "../../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-resident-details',
  templateUrl: './resident-details.component.html',
  styleUrls: ['./resident-details.component.css']
})
export class ResidentDetailsComponent implements OnInit {

  @Input() public appuser: User;

  constructor(
      public api: ApiService,
      public message: NzMessageService,
      public modalService: NzModalService
  ) { }

  ngOnInit(): void {
  }

  public setBlockUser(appuser: Appuser_Users_Objects, state: boolean): void {
    if (state) {
      appuser.blocked = state
      this.api.setAppuserUsersObjects(appuser).subscribe((appUser: Appuser_Users_Objects) => {
        this.message.info('Bewohner wurde Blockiert');
      });
    } else {
      appuser.blocked = state
      this.api.setAppuserUsersObjects(appuser).subscribe((appUser: Appuser_Users_Objects) => {
        this.message.info('Bewohner wurde entblockiert');
      });
    }

  }

  public editAppuser(appuser: User): void {
    const editModal = this.modalService.create({
      nzContent: AppuserEditComponent,
      nzWidth: 700,
      nzComponentParams: {
        appuser: appuser
      },
      nzFooter: null,
    });

    editModal.afterClose.subscribe((data: any) => {
    });
  }

  public capitalize(s): void {
    return s[0].toUpperCase() + s.slice(1);
  }

}
