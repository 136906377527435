import {Component, Input, OnInit} from '@angular/core';
import {CheckList, CheckListTemplate} from '../../../../../../../database-models';

@Component({
  selector: 'app-checklist-details',
  templateUrl: './checklist-details.component.html',
  styleUrls: ['./checklist-details.component.scss']
})
export class ChecklistDetailsComponent implements OnInit {

  @Input()
  public checkList: CheckList;

  constructor() { }

  ngOnInit(): void {
  }

}
