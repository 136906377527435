<div nz-popover [nzPopoverContent]="contentTemplate" [style.border-color]="ticket.state?.background_color"
     nzPopoverTrigger="hover" (click)="openTicket()" class="ticket-tag" *ngIf="ticket">
  <div class="oval"  [style.background-color]="ticket.state?.background_color"
       [style.color]="ticket.state?.color">
    <nz-avatar *ngIf="ticket.employee"
               [nzSrc]="ticket.employee.avatarPath ? backendURL + '/' + ticket.employee.avatarPath : ''"
               [nzIcon]="ticket.employee.avatarPath ? backendURL + '/' + ticket.employee.avatarPath : 'user'"
               style="vertical-align: middle;"
    ></nz-avatar>
    <span class="state">{{ ticket.state?.name }}

  </span>

  </div>

  <span class="title">
    <small>{{ ticket.ticketNumber || 'Ticket' }}</small><br>
    {{ ticket.name }}
  </span>
</div>
<ng-template #contentTemplate>
  <app-ticket-overview [openNewTab]="true" [activeTicket]="ticket"></app-ticket-overview>
</ng-template>
