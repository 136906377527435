import {Component, HostListener, OnInit} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {MessageService} from '../../services/message.service';
import * as moment from 'moment';
import {NotificationService} from '../../services/notification.service';
import {GlobalSearchComponent} from "../../components/global-search/global-search/global-search.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {environment} from "../../../../environments/environment";
import {TicketService} from '../../services/ticket.service';
import {TimetrackingService} from "../../services/timetracking.service";
import {GlobalnewsService} from '../../services/globalnews.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  searchVisible = false;
  quickViewVisible = false;
  isFolded: boolean;
  isExpand: boolean;
  public user: User;
  public backendURL = environment.apiURL;

  @HostListener("window:focus")
  protected onFocus() {
    // this is where you may want to reload your data
    console.log('FOCUS: Reload Timestamps');
    this.timeService.getEmployeeTimestamps();
  }

  constructor(private themeService: ThemeConstantService,
              public authservice: AuthenticationService,
              private api: ApiService,
              private message: MessageService,
              private modalService: NzModalService,
              public timeService: TimetrackingService,
              public ticketService: TicketService,
              public notificationService: NotificationService,
              public globalNewsService: GlobalnewsService
  ) {
    this.authservice.currentUserSubject.subscribe(user => {
      this.user = user;
    });
    this.getCurrentUser();
    setInterval(() => {
      this.getCurrentUser();
    }, 300000);
  }

  public getCurrentUser(): void {
    this.api.getUsers('me').subscribe((user: User) => {
      this.authservice.setCurrentUser(user);
      this.user = user;

    /*  // @ts-ignore
      zE('messenger', 'loginUser', function (callback) {
        callback(user.jwt);
      });
      localStorage.setItem('zJWT', user.jwt);*/


    }, onerror => {
      console.log(onerror);
    });

  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }


  public getTime(date: Date): string {
    const momentDate = moment(date);
    return momentDate.locale('de').fromNow(true).toString();
  }

  public globalSearch(): void {
    const newObjectModal = this.modalService.create({
      nzContent: GlobalSearchComponent,
      nzComponentParams: {
        hideLinkTaskBtn: false,
        hideLinkTicketBtn: false,
        showInfotxt: true,
        showSearchFilter: true,
        searchPlaceholder: 'Globale Suche nach Aufgaben, Kunden, Konakte und Tickets'
      },
      nzWidth: '1150px',
      nzFooter: null,
      nzCloseIcon: ''
    });
    newObjectModal.afterClose.subscribe((data: any) => {
    });
  }
}
