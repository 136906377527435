<nz-spin [nzSpinning]="loading">
  <h2>Ticket erstellen</h2>
  <nz-divider></nz-divider>
  <div nz-row>
    <form *ngIf="ticket" nz-form nzLayout="vertical" [formGroup]="ticketForm">
      <div nz-row>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control nzErrorTip="Bitte geben Sie ein Objekt an">
              <app-object-customer-select [reference]="ticket.object || ticket.contact" [multiple]="false"
                                          [roomSelect]="true" [(room)]="ticket.room"
                                          (referenceChange)="referenceSelected($event)"></app-object-customer-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="priority_id">Priorität</nz-form-label>
            <nz-form-control>
              <nz-select style="width: 100%" formControlName="priority_id" nzShowSearch
                         nzPlaceHolder="Priorität wählen">
                <nz-option *ngFor="let priority of priorities" [nzLabel]="priority.name" [nzValue]="priority.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item class="m-l-20">
            <nz-form-label nzFor="priority_id">Einstellungen</nz-form-label>
            <nz-form-control>
              <label nz-checkbox formControlName="private" nz-tooltip
                     nzTooltipTitle="Wenn ein Ticket als Privat markiert ist, können nur Mitarbeiter und der Melder das Ticket sehen.">Privates
                Ticket</label>

              <label nz-checkbox nz-tooltip
                     nzTooltipTitle="Hier können Sie festlegen ob bei Abschließen eines Tickets noch ein Arbeitprotokoll erstellt werden muss"
                     nz-checkbox formControlName="worklogneeded">Arbeitsprotokoll Pflicht</label>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label nzFor="name" nzRequired>Titel</nz-form-label>
            <nz-form-control nzErrorTip="Bitte geben Sie einen Titel an">
              <input formControlName="name" nz-input placeholder="Titel">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label nzFor="description">Beschreibung
            </nz-form-label>

            <quill-editor formControlName="description" [style]="{height: '200px'}" [modules]="editorConfig"
                          placeholder=""></quill-editor>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>
</nz-spin>

<button class="m-t-50" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen</button>
<button nz-button nzType="primary" class="m-t-50 pull-right" type="submit" (click)="createTicket()"
        [disabled]="ticketForm.invalid">
  Speichern
</button>


<ng-template let-task="data" let-notification #notificationTemplate>
  <p>Das Ticket wurde unter der Ticketnummer <strong>{{ ticket.ticketNumber }}</strong> erstellt.</p>
  <a [routerLink]="'tickets/' + ticket.id" (click)="notification.close()">Ticket ansehen</a>
</ng-template>
