import {Component, Input, OnInit, Optional} from '@angular/core';
import {Appuser_Users_Objects, Object, User} from '../../../../../../database-models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-appuser-edit',
  templateUrl: './appuser-edit.component.html',
  styleUrls: ['./appuser-edit.component.css']
})
export class AppuserEditComponent implements OnInit {

  @Input() public appuser: User;

  public form: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    @Optional() private modal: NzModalRef
  ) {
  }

  public ngOnInit() {
    console.log('appUser', this.appuser)
    this.form = this.formBuilder.group({
      appuser_type: [this.appuser?.Appuser_Users_Objects?.appuser_type, [Validators.required]],
      house_number: [this.appuser?.Appuser_Users_Objects?.house_number, [Validators.required]],
    });
  }

  public updateAppuser(): void {
    const sendData = this.form.getRawValue();
    this.appuser.Appuser_Users_Objects.appuser_type = sendData.appuser_type;
    this.appuser.Appuser_Users_Objects.house_number = sendData.house_number;

    this.api.setAppuserUsersObjects(this.appuser.Appuser_Users_Objects).subscribe((object: Object) => {
      this.message.success('Erfolgreich gespeichert');
      this.modal.close(object);
    });
  }


  public cancel(): void {
    if (this.form.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }
}
