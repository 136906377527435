import {Component, Input, OnInit} from '@angular/core';
import {CheckList, CheckListTemplate, WorkLog} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef} from "ng-zorro-antd/modal";
import {NzNotificationService} from "ng-zorro-antd/notification";


@Component({
  selector: 'app-worklog-edit-checklist',
  templateUrl: './worklog-edit-checklist.component.html',
  styleUrls: ['./worklog-edit-checklist.component.scss']
})
export class WorklogEditChecklistComponent implements OnInit {

  public checkListTemplates: CheckListTemplate[];
  public selectedCheckListTemplate: CheckListTemplate;

  @Input()
  public checkList: CheckList;

  @Input()
  public readOnly: boolean = false;

  public isLoading = false;

  @Input()
  public worklog: WorkLog;



  constructor(
    public api: ApiService,
    private message: NzMessageService,
    public modal: NzModalRef,
    private notification: NzNotificationService,
  ) {
  }

  public ngOnInit(): void {
    if (this.worklog && this.worklog.object) {
      this.getTemplatesByObjectId();
    }
  }


  public getTemplatesByObjectId(): void {
    this.isLoading = true;
    this.api.getCheckListTemplatesByObjectId(this.worklog.object.id).subscribe((templates: CheckListTemplate[]) => {
      this.checkListTemplates = templates;
      this.isLoading = false;
    }, onerror => {
      this.notification.error('Fehler','Checklisten-Vorlagen konnten nicht geladen werden');
      this.isLoading = false;
    });
  }

  public selectTemplate(tpl: CheckListTemplate): void {
    console.log(tpl);
    this.checkList = {
      name: tpl.name,
      list: tpl.list,
      worklog_id: this.worklog.id
    } as CheckList;
  }



  public save(): void {
    this.checkList.object_id = this.worklog.object.id;
    this.calcItems();

    this.api.setCheckList(this.checkList).subscribe(res => {
      if (res.id) {
        this.checkList = res;
      }
      this.modal.triggerOk();
    }, error => {
      this.notification.error('Fehler', 'Checkliste konnte nicht gespeichert werden');
    });



  }

  private calcItems(): void {
    this.checkList.items = 0;
    this.checkList.items_ok = 0;
    this.checkList.items_not_ok = 0;
    for (const item of this.checkList.list) {
      this.checkList.items++;
      if (item.state === 'ok') {
        this.checkList.items_ok++;
      } else if (item.state === 'not_ok') {
        this.checkList.items_not_ok++;
      }
    }
  }


}
