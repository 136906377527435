import {Component, Input, OnInit} from '@angular/core';
import {Object, Wasteplan} from '../../../../../../../database-models';
import {
  localeDe,
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscPageChangeEvent,
  MbscResource,
  MbscSelectedDateChangeEvent
} from '@mobiscroll/angular';
import * as moment from 'moment/moment';
import {ApiService} from '../../../../services/api.service';
import {groupBy} from '../../../../../../../helper-functions';
import * as uuid from 'uuid';

@Component({
  selector: 'app-wasteplan-scheduler-list',
  templateUrl: './wasteplan-scheduler-list.component.html',
  styleUrls: ['./wasteplan-scheduler-list.component.scss']
})
export class WasteplanSchedulerListComponent implements OnInit {

  public combineEvents= false;

  public wastePlans: Wasteplan[];
  public objects: Object[] | any;

  public myEvents: MbscCalendarEvent[] = [];
  public timeOut: MbscCalendarEvent[] = [];
  public timeIn: MbscCalendarEvent[] = [];

  public taskDuration: number = 30;

  public filter = {
    start: moment().toString(),
    end: moment().toString()
  };

  public eventSettings: MbscEventcalendarOptions = {
    locale: localeDe,
    theme: 'ios',
    themeVariant: 'light',
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false,
    eventDelete: false,
    view: {
      timeline: {
        type: 'week',
        resolutionHorizontal: 'day',
      }
    },
    onEventClick: (event, inst) => {

    }
  };

  constructor(
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    const durationTmp = localStorage.getItem('wasteplanSchedulerTaskDuration');
    this.taskDuration = +durationTmp || 30;
    this.getWastePlans();
  }

  public getWastePlans(event?: MbscSelectedDateChangeEvent): void {

    localStorage.setItem('wasteplanSchedulerTaskDuration', this.taskDuration.toString());

    if (event) {
      this.filter.start = event.date.toString();
      this.filter.end = event.date.toString();
    }

    this.api.getWasteplan(this.filter).subscribe((plans: Wasteplan[]) => {
      this.wastePlans = plans;
      this.myEvents = [];
      this.timeOut = [];
      this.timeIn = [];

      for (let wasteplan of plans) {
        const plan = {
          id: wasteplan.id,
          description: 'Abholung der Tonne am ' + moment(wasteplan.time).format('DD.MM.YYYY'),
          start: '08:00',
          end: (this.taskDuration < 60 ? '08:' + this.zeroPad(this.taskDuration, 2) : '09:00'),
          time: wasteplan.time,
          color: wasteplan.color,
          tonne: wasteplan.title,
          object: wasteplan.object,
          object_id: wasteplan.object_id,
          group: wasteplan.object.name,
          wasteplan: wasteplan
        } as MbscCalendarEvent;
        //this.myEvents.push(plan);

        const outPlan = Object.assign({}, plan);
        outPlan.id = uuid.v4();
        outPlan.title = plan.tonne + ' rausstellen';
        outPlan.time = wasteplan.outTime;
        outPlan.type = 'out';
        outPlan.done = !!wasteplan.out_user_id;
        if (!wasteplan.task_out_id) {
          this.timeOut.push(outPlan);
        }


        if (wasteplan.inTime) {
          const inPlan = Object.assign({}, plan);
          inPlan.id = uuid.v4();
          inPlan.title = plan.tonne + ' reinstellen';
          inPlan.time = wasteplan.inTime;
          inPlan.type = 'in';
          inPlan.done = !!wasteplan.in_user_id;
          if (!wasteplan.task_in_id) {
            this.timeIn.push(inPlan);
          }
        }
      }
      this.myEvents = [...this.timeOut, ...this.timeIn];
      console.log(this.myEvents);

      this.myEvents = this.myEvents.filter(event => {
        return moment(event.time).isSame(this.filter.start, 'day') || moment(event.time).isSame(this.filter.end, 'day');
      });


      this.myEvents.sort((a, b) => {
        // @ts-ignore
        return new Date(a.start) - new Date(b.start);
      });
      this.objects = groupBy(this.myEvents, 'group');
      console.log(this.objects);
      if (this.combineEvents) {
        for (let key in this.objects) {
          let tmp: any = this.objects[key];
          this.objects[key] = {
            title: tmp.length + ' Termine ...',
            children: tmp,
            start: tmp[0].start,
            end: tmp[0].end,
            color: '#F4F4F4'
          }
        }
        console.log(this.objects);
      }
    });


  }

  private zeroPad(num, places): string {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }


}
