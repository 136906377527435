<div class="m-t-10" *ngIf="task">
  <strong class="display-block">Titel</strong>
  {{ task.title }}
</div>
<div class="m-t-10" *ngIf="task.description">
  <strong class="display-block">Beschreibung</strong>
  {{ task.description }}
</div>
<div class="m-t-10">
  <strong class="display-block">Termin</strong>
  <ng-container *ngIf="task.backlog">
  <strong>BACKLOG</strong> <br> Zeit nicht festgelegt
</ng-container>
  <ng-container *ngIf="!task.backlog">
    <i nz-icon nzType="calendar" nzTheme="outline"></i> {{task.start | date:'dd.MM.y'}}<br>
    <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{task.start | date:'HH:mm'}} - {{task.end | date:'HH:mm'}} Uhr <ng-container *ngIf="moment(task.end).diff(task.start, 'day') > 0">(+{{moment(task.end).diff(task.start, 'day')}} Tag)</ng-container>
  </ng-container>
</div>
<ng-container *ngIf="task.worklogs">
  <div class="m-t-10">
    <strong class="display-block" *ngIf="task.worklogs.length > 0">Verknüpftes
      Arbeitsprotokoll</strong>
    <p class="m-0 p-0" *ngFor="let wl of task.worklogs">
      <a class="m-r-5" nz-tooltip="Arbeitsprotokoll öffnen" nzTooltipPlacement="left" (click)="editWorklog(wl)">Arbeitsprotokoll</a>
      <small>{{wl.createdAt | date:'dd.MM.y HH:mm'}}</small>
      <a class="m-l-5" nz-tooltip="PDF runterladen" nzTooltipPlacement="right"
         (click)="downloadPDF(wl)">
        <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
      </a>
    </p>
  </div>
</ng-container>
<div *ngIf="task.object" class="m-t-10">
  <strong class="display-block">Objekt</strong>
  <a [routerLink]="'/objects/' + task.object.id">{{ task.object?.name }}</a>
</div>
<div *ngIf="task.customer" class="m-t-10">
  <strong class="display-block">Kunde</strong>
  {{ task.customer?.companyname ||
task.customer.firstname + ' '
+ task.customer.firstname}}
</div>
<div *ngIf="task.user" class="m-t-10">
  <strong class="display-block">Zugewiesener Mitarbeiter</strong>
  <nz-avatar nzSize="small" class="m-r-5"
             [nzSrc]="task.user.avatarPath ? backendURL + '/' + task.user.avatarPath : ''"
             [nzIcon]="task.user.avatarPath ? backendURL + '/' + task.user.avatarPath : 'user'"
             style="vertical-align: middle;"
  ></nz-avatar>
  {{ task.user.name }}
</div>
<div *ngIf="task.team" class="m-t-10">
  <strong class="display-block">Zugewiesenes Team</strong>
  {{ task.team.name }}
</div>
<div *ngIf="(!task.recurring && task.recurringDone?.length > 0)" class="m-t-10">

  <nz-tag nzColor="green">Aufgabe ist Erledigt</nz-tag>
</div>


