import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {Task} from "../../../../../../../database-models";
import * as moment from "moment";
import {MbscCalendarEvent} from "@mobiscroll/angular";
import {EditTaskComponent} from "../edit-task/edit-task.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'task-tag',
  templateUrl: './task-tag.component.html',
  styleUrls: ['./task-tag.component.scss']
})
export class TaskTagComponent implements OnInit {
  public backendURL = environment.apiURL;

  @Input()
  set taskId(value: string) {
    this._taskId = value;
    this.getTask();
  }

  private _taskId: string;
  public task: Task;

  constructor(private api: ApiService,
              private modalService: NzModalService) {
  }

  ngOnInit(): void {
  }

  public getTask() {
      this.api.getTask(this._taskId).subscribe(task => {
        this.task = task;
      })
    }

  protected readonly moment = moment;

  public editTask(task?: Task | MbscCalendarEvent): void {
    const newObjectModal = this.modalService.create({
      nzContent: EditTaskComponent,
      nzComponentParams: {
        task: task,
      },
      nzWidth: '1100px',
      nzFooter: null,
      nzClosable: true,
      nzOnCancel: (instance) => {
      },
      nzOnOk: (instance) => {
        // @ts-ignore
        this.taskChange?.emit(instance.task);
        console.log(instance.task);
      },
    });
  }

}
