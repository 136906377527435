<div class="box-header d-flex align-items-center justify-content-between p-b-10">

  <div>

  </div>


  <div class="d-flex">

    <button class="m-h-10" nz-button nzType="default" (click)="getUsers()"><i
      nz-icon nzType="reload"></i>Aktualisieren
    </button>
    <nz-input-group [nzPrefix]="prefiXTemplate">
      <input nz-input placeholder="Name suchen" nzSize="small" [(ngModel)]="nameSearch"
             (ngModelChange)="filter()">
    </nz-input-group>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>

</div>

<nz-card>
  <nz-spin [nzSpinning]="userLoading">
    <nz-table #usersTable [nzData]="users" nzSize="small">
      <thead>
      <tr>
        <th [nzWidth]="'5%'"></th>
        <th [nzWidth]="'20%'" [nzSortFn]="orderFn.name" [(nzSortOrder)]="sortOrders.name"
            (nzSortOrderChange)="sortChanged($event)">Name
        </th>

        <th>Letzter Stempel</th>
        <th [nzWidth]="'10%'">Zeiterfassung</th>

      </tr>
      </thead>
      <tbody>
      <tr class="pointer"
          [ngClass]="{ 'noTimeTracking' : !dataItem.renewinterval.module_timetracking}"
          *ngFor="let dataItem of usersTable.data"
          [routerLink]="'/timetracking/' + dataItem.id">
        <td style="border-left: 5px solid {{dataItem.color || '#FFFFFF'}}">
          <nz-avatar
            [nzSrc]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : ''"
            [nzIcon]="dataItem.avatarPath ? backendURL + '/' + dataItem.avatarPath : 'user'"
            style="vertical-align: middle;"
          ></nz-avatar>
        </td>
        <td>
          {{ dataItem.name ? dataItem.name : '' }}
        </td>


        <td>
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'KOMMEN'" nz-icon [nzType]="dataItem.timetracking_timestamps[0]?.timetracking_type?.icon_web || 'tool'"
             nzTheme="outline"></i>
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'PAUSE'" nz-icon nzType="coffee"
             nzTheme="outline"></i>
          <i *ngIf="dataItem.timetracking_timestamps[0]?.action === 'GEHEN'" nz-icon nzType="logout"
             nzTheme="outline"></i>
          <strong
            [ngClass]="{ 'color-orange': dataItem.timetracking_timestamps[0]?.action === 'KOMMEN','color-blue': dataItem.timetracking_timestamps[0]?.action === 'PAUSE','color-black': dataItem.timetracking_timestamps[0]?.action === 'GEHEN'}">
            {{ dataItem.timetracking_timestamps[0]?.timetracking_type?.name || dataItem.timetracking_timestamps[0]?.action }}
          </strong>
          <small>
            <p class="m-0">
              {{ dataItem?.timetracking_timestamps[0]?.timestamp | date : 'dd.MM.yyy HH:mm' }}
            </p>
          </small>
        </td>
        <td style="text-align: center">
          <nz-tag
            [nz-tooltip]="dataItem.renewinterval.module_timetracking ? 'Zeiterfassung ist Aktiv' : 'Zeiterfassung ist Inaktiv'"
            [nzColor]="dataItem.renewinterval.module_timetracking ? 'green' : 'red'">
            <i nz-icon [nzType]="dataItem.renewinterval.module_timetracking ? 'check' : 'close'"
               nzTheme="outline">
            </i>
          </nz-tag>
        </td>

      </tr>
      </tbody>
    </nz-table>
  </nz-spin>
</nz-card>
