import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {Contact, Customer, Object, State, Task, Ticket, User} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../services/authentication.service";
import {HttpParams} from "@angular/common/http";
import {EditTaskComponent} from "../../task/edit-task/edit-task.component";
import {EditContactV2Component} from "../../edit-contact-v2/edit-contact-v2.component";
import {environment} from "../../../../../environments/environment";
import {NzMessageService} from "ng-zorro-antd/message";
import {duration} from "moment";
import {sendMessageToMaster} from "@angular/compiler-cli/ngcc/src/execution/cluster/utils";

@Component({
  selector: 'app-global-search-v2',
  templateUrl: './global-search-v2.component.html',
  styleUrls: ['./global-search-v2.component.scss']
})
export class GlobalSearchV2Component implements OnInit {

  @ViewChild('globalSearchBackdrop', {static: true})
  public globalSearchBackdrop: ElementRef;


  public searchResult: {
    tasks: Task[],
    tickets: Ticket[],
    objects: Object[],
    contacts: Contact[],
    users: User[]
  };

  @Input() public searchPlaceholder: string = '';

  @Input() public searchCategories: any[] = ['TICKETS', 'TASKS', 'OBJECTS', 'CONTACTS', 'USERS'];

  public active: boolean = false;

  public allChecked = true;
  public showContentDiv = false;
  public isTouched = false;

  private date: Date = new Date();
  public backendURL = environment.apiURL;

  public selectedCategories = [
    {
      name: 'Tickets',
      isChecked: true,
      key: 'TICKETS'
    },
    {
      name: 'Aufgaben',
      isChecked: true,
      key: 'TASKS'
    },
    {
      name: 'Objekte',
      isChecked: true,
      key: 'OBJECTS'
    },
    {
      name: 'Mitarbeiter',
      isChecked: true,
      key: 'USERS'
    },
    {
      name: 'Kontakte',
      isChecked: true,
      key: 'CONTACTS'
    },
  ];

  public filter = {
    search: null,
    searchCategories: this.searchCategories,
    reference: [],
    user: null
  };

  public showResults: boolean = false;
  public isLoading: boolean = false;

  @ViewChild('resultDiv', { read: ElementRef }) resultDiv?: ElementRef;

  constructor(
      public api: ApiService,
      private modalService: NzModalService,
      public route: ActivatedRoute,
      public authenticationService: AuthenticationService,
      private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public globalSearchFilter(category?: string, limit?: number): void {
    if (this.filter?.search.length > 0) {
      this.showContentDiv = true;
      this.showResults = true;
      this.isLoading = true;
      let httpParams = new HttpParams();

      if (category) {
        this.selectedCategories.forEach(sC => {
          sC.isChecked = sC.key === category;
          if (sC.isChecked) {
            httpParams = httpParams.append('searchCategories', category);
            this.allChecked = false;
          }
        });
        this.searchCategories = [category];
      } else {
        for (const category of this.selectedCategories) {
          if (category.isChecked) {
            httpParams = httpParams.append('searchCategories', category.key);
          }
        }
      }

      if (limit) {
        httpParams = httpParams.append('limit', limit);
      } else {
        if (httpParams.has('searchCategories') && httpParams.getAll('searchCategories')?.length >= 2) {
          httpParams = httpParams.append('limit', 10);
        } else {
          httpParams = httpParams.append('limit', 100);
        }
      }

      if (this.filter.search) {
        let search = this.filter.search
        httpParams = httpParams.append('search', search);
      }

      if (this.filter.user) {
        httpParams = httpParams.append('userId', this.filter.user.id);
      }

      if (this.filter.reference) {
        let objectIds = [];
        let customerIds = [];
        try {
          for (let ref of this.filter.reference) {
            if (ref.member === 'Object') {
              objectIds.push(ref.id);
            }
            if (ref.member === 'Contact') {
              customerIds.push(ref.id);
            }
          }
        } catch (e) {
          this.filter.reference = [];
        }

        httpParams = httpParams.append('objectIds', JSON.stringify(objectIds));
        httpParams = httpParams.append('customerIds', JSON.stringify(customerIds));
      }

      this.api.globalSearch(httpParams).subscribe(result => {
        this.searchResult = result;
        if (!this.checkResult(this.searchResult)) {
          this.showResults = false;
        }
        this.isLoading = false;
      }, error => {
        console.log(error)
        if (error.status === 400) {
          this.showResults = false;
          this.isLoading = false;
        }
      });
    }
  }

  public keyEnter(e: KeyboardEvent): void {
    setTimeout(() => {
      if (e.key === 'Enter') {
        this.globalSearchFilter();
      }
    }, 1);
  }

  public onChangeCheckBox(tag: any): void {
    tag.isChecked = !tag.isChecked;

    this.searchCategories = this.selectedCategories
        .filter(item => item.isChecked)
        .map(item => item.key);

    this.allChecked = this.selectedCategories.every(item => !item.isChecked) ? false :
        this.selectedCategories.every(item => item.isChecked);

    this.globalSearchFilter();
  }

  public updateAllChecked(): void {
    if (this.allChecked) {
      this.selectedCategories.forEach(item => (item.isChecked = true));
    } else {
      this.selectedCategories.forEach(item => (item.isChecked = false));
    }

    this.searchCategories = this.selectedCategories.filter(item => item.isChecked).map(item => item.key);

    this.globalSearchFilter();
  }

  public async editTask(task?: Task): Promise<void> {
    this.toggleSearch(false);
    this.api.getTask(task.id).subscribe(taskDB => {
      const editTaskModal = this.modalService.create({
        nzContent: EditTaskComponent,
        nzComponentParams: {
          task: taskDB
        },
        nzWidth: '1100px',
        nzFooter: null,
        nzClosable: false,
        nzMaskClosable: false,
        nzOnCancel: instance => {
          console.log(instance);
        },
        nzOnOk: instance => {


        }
      });
      editTaskModal.afterClose.subscribe(res => {
        this.route.paramMap.subscribe(params => {
          if (params.get('modalMode') === 'new') {
            this.router.navigate(['tasks']);
          }
        });
      });
    });
  }

  public navigateToTicketPage(ticketId: string) {
    this.router.navigate(['/tickets', ticketId]);
    this.toggleSearch(false);
    this.modalService.closeAll();
  }

  public editContact(contact: Contact): void {
    this.toggleSearch(false);
    const newModal = this.modalService.create({
      nzContent: EditContactV2Component,
      nzComponentParams: {
        contact
      },
      nzWidth: '1000px',
      nzFooter: null,
      nzMaskClosable: false
    });
    newModal.afterClose.subscribe((data: any) => {
    });
  }

  public navigateToObjectPage(objectId: string) {
    this.router.navigate(['/objects/', objectId]);
    this.toggleSearch(false);
  }

  public navigateToUser(userId: string) {
    this.router.navigate(['/users/', userId]);
    this.toggleSearch(false);
  }

  public navigateToTimeTracking(userId: string) {
    this.router.navigate(['/timetracking/', userId]);
    this.toggleSearch(false);
  }

  public toggleSearch(active: boolean): void {
    this.active = active;
    if (!active) {
      this.showResults = false;
      this.searchResult = null
      this.showContentDiv = false;
      this.filter.searchCategories = this.searchCategories
      if (this.filter.search === ' ') {
        this.filter.search = null
        this.isTouched = false;
      }
    }
  }

  public isOverdue(ticket: Ticket, state?: State): boolean {
    if (!ticket.doUntil || ticket.done || state?.isClosed) {
      return false;
    } else {
      const convDate = new Date(ticket.doUntil);
      return convDate < this.date;
    }
  }

  public checkResult(searchResult: any): boolean {
    return (searchResult && (searchResult.tasks?.length > 0 ||
            searchResult.tickets?.length > 0 ||
            searchResult.objects?.length > 0 ||
            searchResult.users?.length > 0 ||
            searchResult.contacts?.length > 0)
    );
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    this.toggleSearch(false)
    this.blurInput();
  }

  public checkIfAtLeastOneCategorySelected(): boolean {
    const atLeastOneSelected = this.selectedCategories.some(category => category.isChecked);
    return atLeastOneSelected;
  }

  private blurInput(): void {
    const inputElement = document.getElementById('blurInput') as HTMLInputElement;
    if (inputElement) {
      inputElement.blur();
    }
  }

  public sendEmail(email: string) {
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  }


  public startCall(phone: string) {
    const phoneCall = `tel:${phone}`;
    window.location.href = phoneCall;
  }

  public onChange(): void {
    this.searchResult = null
    if (this.filter.search === '') {
      this.isTouched = false
    } else {
      this.isTouched = true;
    }
  }
}
