import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeTrackingDays, TimeTrackingMonth} from '../../../../../../../../database-models';
import {momentTimezone} from '@mobiscroll/angular';
import * as moment from 'moment';
import {ApiService} from '../../../../../services/api.service';
import {NotificationService} from '../../../../services/notification.service';
import {MessageService} from '../../../../services/message.service';

@Component({
  selector: '[manualTimes]',
  templateUrl: './manual-times.component.html',
  styleUrls: ['./manual-times.component.css']
})
export class ManualTimesComponent implements OnInit {
  public momentPlugin = momentTimezone;
  public valuesAsTime: TimeTrackingDays | any;

  private _timestamps: TimeTrackingDays;

  get timestamps(): TimeTrackingDays {
    return this._timestamps;
  }

  @Input()
  set timestamps(value: TimeTrackingDays) {
    this._timestamps = value;
    this.valuesAsTime = {...value};

    this.valuesAsTime.worktime = moment(value.date).startOf('day').add(value.worktime, 'seconds');
    this.valuesAsTime.breaktime = moment(value.date).startOf('day').add(value.breaktime, 'seconds');
    this.valuesAsTime.absenttime = moment(value.date).startOf('day').add(value.absenttime, 'seconds');
    this.valuesAsTime.needed = moment(value.date).startOf('day').add(value.needed, 'seconds');

    console.log(this.valuesAsTime);
  }

  @Output()
  public timestampsChange: EventEmitter<TimeTrackingDays> = new EventEmitter<TimeTrackingDays>();

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  public save(): void {
    this.timestamps.worktime = this.toMilliseconds(this.valuesAsTime.worktime);
    this.timestamps.breaktime = this.toMilliseconds(this.valuesAsTime.breaktime);
    this.timestamps.absenttime = this.toMilliseconds(this.valuesAsTime.absenttime);
    this.timestamps.needed = this.toMilliseconds(this.valuesAsTime.needed);

    this.update();
  }

  private toMilliseconds(time: any): number {
    let timeArr: any;
    if (time.m) {
      timeArr = time.m.format('HH:mm:ss').split(':');
    } else {
      timeArr =  moment(time).format('HH:mm:ss').split(':');
    }

    return (timeArr[0] * 3600) + (timeArr[1] * 60);
  }

  public reset(): void {
    this.timestamps.manualEntry = false;
    this.update();
  }

  private update(): void {
    this.api.setTimetrackingDayManual(this.timestamps).subscribe(res => {
      this.messageService.success('Erfolgreich gespeichert. Neuberechnung wird geladen.');
      this.timestampsChange.emit(this.timestamps);
    }, error => {
      this.messageService.error('Konnte nicht gespeichert werden!');
    })
  }

  protected readonly momentTimezone = momentTimezone;
}
