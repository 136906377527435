<div class="print-header">
  <img src="assets/images/logo/logo.svg" alt="Logo">
</div>

<div class="center-content headline">
  <h2>Alles an einem vertrauten Ort</h2>
  <h1>Die <strong>App</strong> von {{ object?.company.name}}</h1>
</div>

<div class="anleitung">
  <p>In nur drei Schritten sind Sie startklar:</p>

  <ol>
    <li>
      Laden Sie sich die App "Hausmeisterapp.com" aus dem App-Store oder PlayStore herunter
    </li>
    <li>
      Starten Sie die App und registrieren Sie sich ganz unkompliziert
    </li>
    <li>
      Fügen Sie Ihr Objekt hinzu: Geben Sie dazu den Objektschlüssel ein
    </li>
  </ol>
</div>

<div class="objekt-info">
  <div nz-row>
    <div nz-col nzSpan="14">
      <div class="object-key-container">
        <span>Objekt-Schlüssel:</span>
        <div class="object-key">
          {{ object?.key }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="10">

      <div class="object-key-container">
        <span>QR-Code zum Objekt:</span>
        <div class="object-qr">
          <qrcode *ngIf="object?.key" [size]="200" [level]="1" [qrvalue]="object.key"></qrcode>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="print-footer center-content" *ngIf="object">
  <strong>{{ object.name }} &bull; {{ object.address_line1 }} &bull; {{ object.zip }} {{ object.city}}</strong><br>
  Hausmeisterapp.com &bull; Ein Service von NetBrick - IT Solutions
</div>
