import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Task, Ticket, WorkLog} from "../../../../../../../database-models";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiService} from "../../../../services/api.service";
import {environment} from "../../../../../environments/environment";
import {EditWorklogComponent} from '../../edit-worklog/edit-worklog.component';
import {saveAs} from "file-saver";
import {MbscCalendarEvent} from "@mobiscroll/angular";
import {EditTaskComponent} from "../edit-task/edit-task.component";
import * as moment from "moment";




@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent implements OnInit {

  public backendURL = environment.apiURL;

  public worklog: WorkLog;

  @Input()
  public task: Task;

  @Output()
  public taskChange: EventEmitter<Task> = new EventEmitter<Task>();


  constructor(private modalService: NzModalService,
              private api: ApiService) { }

  ngOnInit(): void {
    this.getTask();
  }

  public editWorklog(worklog: WorkLog): void {
    const newObjectModal = this.modalService.create({
      nzContent: EditWorklogComponent,
      nzComponentParams: {
        worklogId: worklog.id
      },
      nzWidth: '1500px',
      nzFooter: null,
      nzMaskClosable: true,
    });

    newObjectModal.afterClose.subscribe((data: any) => {
    });
  }

  protected readonly moment = moment;

  public downloadPDF(worklog: WorkLog): void {
    this.api.downloadWorklogPDF({id: worklog.id}).subscribe((res) => {
      let name = 'AP_' + moment(worklog.start).format('DD-MM-YYYY') + '.pdf'
      saveAs(res, name);
      saveAs(res, worklog.id + ".pdf");
    });
  }

  public getTask(){
    this.api.getTask(this.task.id).subscribe(task => {
      this.task = task;
      // @ts-ignore
      this.taskChange.emit(task);
    })
  }

}
