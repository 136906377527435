<div id="wl-print-section" class="print-only">
  <app-print-worklog *ngIf="worklog" [worklog]="worklog"
                     [HvId]="worklog?.object?.hausverwaltung_id"></app-print-worklog>
</div>

<!--Header-->
<div>
  <h2 class="m-b-0">Arbeitsprotokoll</h2>

  <div *ngIf="worklog?.id" class="m-b-20">
    <p class="p-0">
      <i *ngIf="worklog.billed" class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i> Protokoll vom
      <strong>{{ worklog?.createdAt | date:'dd.MM.y H:mm' }} Uhr</strong>
      <span
        *ngIf="worklog.updatedAt && (worklog.updatedAt !== worklog.createdAt)"> zuletzt bearbeitet am
        <strong>{{ worklog.updatedAt | date:'dd.MM.y H:mm' }}
          Uhr</strong></span>
      <ng-container *ngIf="worklog.finalized">
        finalisiert am
        <strong>{{ worklog.finalized | date:'dd.MM.y H:mm' }} Uhr</strong>

      </ng-container>
    </p>
  </div>

</div>

<nz-spin [nzSpinning]="loading">
  <ng-container *ngIf="worklog">

    <div nz-row>
      <div nz-col nzSpan="4">

      </div>

      <!-- BUTTONS-->
      <div nz-col nzSpan="20" *ngIf="worklog?.id">
        <div class="pull-right">
          <ng-container *ngIf="!worklog.finalized">
            <button nz-button nzType="link" *appIsManager class="m-r-5"
                    (click)="setFinal()"> Finalisieren
            </button>
          </ng-container>


          <button class="m-r-5" nz-button nzType="default"
                  (click)="openTab('https://service.netbrick.de/help/de-de/5-arbeitsprotokolle/9-arbeitsprotokolle-auswerten')">
            <i nz-icon nzType="question" class="m-r-5"></i> Hilfe
          </button>
          <button class="m-r-5" nz-button nzType="default" (click)="downloadPDF()">
            <i nz-icon nzType="file-pdf" class="m-r-5"></i>
            PDF
          </button>

          <button class="m-r-5" *appIsManager nz-button nzType="default"
                  (click)="shareWorklog()">
            <i nz-icon nzType="share-alt" nzTheme="outline" class="m-r-5"></i> Teilen
          </button>




          <button class="m-r-10" nz-button nz-dropdown nzType="default" [nzDropdownMenu]="optionmenu"
                  nzPlacement="bottomRight">
            <span nz-icon nzType="euro-circle"></span> Abrechnung
          </button>

          <nz-dropdown-menu #optionmenu="nzDropdownMenu">
            <ul nz-menu>
              <ng-container *ngIf="authenticationService.lexofficeApiActive">
                <li (click)="lexofficeService.worklogToInvoice(worklog)" nz-menu-item>
<a>
                    <i nz-icon nzType="file-done" nzTheme="outline"></i>
                    Rechnung in <strong>LexOffice</strong> erzeugen
</a>
                </li>
              </ng-container>
              <ng-container *ngIf="!worklog.billed">
                <li nz-menu-item>
                  <a (click)="bulkUpdateBilled(true)">
                    <i class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i>
                    als 'abgerechnet' markieren
                  </a>
                </li>
              </ng-container>

              <ng-container *ngIf="worklog.billed">
                <li nz-menu-item (click)="bulkUpdateBilled(false)">
                  <a>
                    <i class="text-danger" nz-icon nzType="euro-circle" nzTheme="fill"></i>
                    als 'nicht abgerechnet' markieren
                  </a>
                </li>
              </ng-container>
            </ul>
          </nz-dropdown-menu>

          <button *appIsManager nz-button nzType="default"
                  (click)="deleteWorklog()">
            <i
              nz-icon nzType="delete"></i> Löschen
          </button>
        </div>
      </div>
    </div>

    <nz-card class="m-t-10">
      <div nz-row >
        <div nz-col nzSpan="24">
          <nz-tabset>
            <nz-tab nzTitle="Übersicht">
              <div nz-row>
                <!--FORM-->
                <div nz-col nzSpan="14" class="m-r-35">
                  <form nz-form *ngIf="worklogForm && worklog && worklogId" [formGroup]="worklogForm">
                    <div nz-row>
                      <nz-form-item nz-col nzSpan="12" class="p-r-5">
                        <nz-form-label nzRequired nzFor="description">Start</nz-form-label>
                        <nz-form-control>
                          <nz-date-picker
                            style="width: 100%"
                            [nzShowTime]="{ nzFormat: 'HH:mm' }"
                            nzFormat="dd.MM.yyyy HH:mm"
                            nzPlaceHolder="Bitte setzen Sie eine Startzeit"
                            formControlName="start"
                          ></nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item nz-col nzSpan="12">
                        <nz-form-label nzFor="description">Ende</nz-form-label>
                        <nz-form-control>
                          <nz-date-picker
                            style="width: 100%"
                            [nzShowTime]="{ nzFormat: 'HH:mm' }"
                            nzFormat="dd.MM.yyyy HH:mm"
                            nzPlaceHolder="Setzen Sie eine Endzeit (optional)"
                            formControlName="end"
                          ></nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-row>
                      <nz-form-item nz-col nzSpan="12" class="p-r-5">
                        <nz-form-label nzFor="description">Tätigkeit</nz-form-label>
                        <nz-form-control>
                          <textarea [nzAutosize]="{ minRows: 3, maxRows: 5 }" formControlName="description" nz-input placeholder="Tätigkeit"></textarea>
                        </nz-form-control>
                      </nz-form-item>
                      <nz-form-item nz-col nzSpan="12">
                        <nz-form-label nzFor="internalNote">Interne Notiz</nz-form-label>
                        <i nz-tooltip nzTooltipTitle="Diese Notizen sind nur für interne Zwecke und werden weder beim Teilen des Arbeitsprotokolles, noch auf der PDF-Version angezeigt."
                                                    class="m-t-5"
                                                    style="color: #FF8F00;"
                                                    nz-icon
                                                    nzType="info-circle"
                                                    nzTheme="outline"></i>
                        <nz-form-control>
                          <textarea class="internal-comment" [nzAutosize]="{ minRows: 3, maxRows: 5 }" formControlName="internalNote" nz-input placeholder="Interne Notiz"></textarea>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-row>

                      <nz-form-item nz-col nzSpan="12">
                        <app-object-customer-select [allowClear]="false" #referenceSelect [worklog]="worklog"
                                                    [roomSelect]="true" [(room)]="worklog.room" [optional]="true"
                                                    [multiple]="false"
                                                    [disabled]="worklog.finalized" [(reference)]="reference"
                                                    (referenceChange)="referenceSelected($event)"></app-object-customer-select>
                      </nz-form-item>


                      <nz-form-item nz-col nzSpan="12" class="p-l-5">
                        <nz-form-label nzRequired nzFor="creator">Ersteller</nz-form-label>
                        <nz-form-control>
                          <nz-select formControlName="creator"
                                     nzShowSearch
                                     nzPlaceHolder="bitte wählen" nzSize="small"
                                     [compareWith]="compareById">
                            <nz-option nzCustomContent *ngFor="let employee of users" [nzLabel]="employee.name"
                                       [nzValue]="employee">
                              {{ employee.name }}
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-row>
                      <nz-form-item nz-col nzSpan="24">
                        <nz-form-label nzFor="involved_users">Beteiligte Mitarbeiter</nz-form-label>
                        <nz-form-control>
                          <nz-select formControlName="involved_users"
                                     nzShowSearch nzMode="multiple" [nzMaxTagCount]="4"
                                     nzPlaceHolder="bitte wählen" nzSize="small"
                                     [compareWith]="compareById">
                            <nz-option nzCustomContent *ngFor="let employee of users" [nzLabel]="employee.name"
                                       [nzValue]="employee">
                              {{ employee.name }}
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <nz-alert class="m-b-10" *ngIf="worklogForm?.errors" nzType="error"
                              [nzMessage]="worklogForm?.errors?.dates"
                              nzShowIcon></nz-alert>
                  </form>
                </div>


                <!--Verknüpfungen-->
                <div nz-col nzSpan="8" style="margin-left: 30px; margin-top: 10px;">
                  <div style="margin-left: 15px;">
                    <div class="m-v-10" *ngIf="!worklog.task_id">
                      <div class="task-container">
                        <button
                          nz-button
                          nzBlock
                          nzSize="small"
                          nzGhost
                          nzType="primary"
                          [hidden]="worklog.finalized"
                          (click)="toggleTaskSearch(); $event.stopPropagation();"
                        >
                          <i nz-icon nzType="schedule" nzTheme="outline"></i> Aufgabe verknüpfen
                        </button>

                        <div class="taskSearch" [ngClass]="{'showTask': showTaskSearch}">
                          <app-global-search #globalSearchTASKComponent [hideLinkTaskBtn]="true"
                                             [searchCategories]="'TASKS'"
                                             (linkTaskToWorklog)="linkTask($event)"
                                             [infoTooltip]="'Hier können Sie nach Aufgabentitel und den zugehörigen Objektnamen suchen.'"
                                             [searchPlaceholder]="'Aufgabe suchen'"></app-global-search>
                        </div>
                      </div>
                    </div>


                    <div *ngIf="worklog.task">
                      <h6><i nz-icon nzType="inbox" nzTheme="outline"></i> Verknüpfung Aufgabe</h6>
                      <div nz-row class="m-b-5" nzJustify="space-around" nzAlign="middle">
                        <task-tag nz-col nzSpan="20" [taskId]="worklog.task_id"></task-tag>
                        <div nz-col nzSpan="4">
                          <button *ngIf="worklog.task_id && !worklog.finalized" (click)="deleteTaskLink(worklog)" nzShape="circle" class="m-l-10"
                                  nz-tooltip="Aufgabenverlinkung aufheben"
                                  nzSize="small" nzDanger nz-button nzType="link"><i nz-icon
                                                                                     nzType="link"
                                                                                     nzTheme="outline"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngIf="worklog.task_id && worklog.ticket_id">
                      <nz-divider></nz-divider>
                    </ng-container>

                    <div *ngIf="worklog.ticket">
                      <h6><i nz-icon nzType="inbox" nzTheme="outline"></i> Verknüpfung Ticket</h6>
                      <div nz-row class="m-b-5" nzJustify="space-around" nzAlign="middle">
                          <ticket-tag nz-col nzSpan="21" [openNewTab]="true" [ticketId]="worklog.ticket_id"></ticket-tag>
                            <div nz-col nzSpan="3">
                              <button *ngIf="worklog.ticket_id && !worklog.finalized" (click)="deleteTicketLink(worklog)" nzShape="circle" class="m-l-10"
                                      nz-tooltip="Verlinkung aufheben"
                                      nzSize="small" nzDanger nz-button nzType="link"><i nz-icon
                                                                                         nzType="link"
                                                                                         nzTheme="outline"></i>
                              </button>
                            </div>
                      </div>
                    </div>

                      <ng-container *ngIf="!worklog.ticket_id">
                        <div class="p-t-10 ticket-container">
                          <button
                            nz-button
                            nzBlock
                            nzSize="small"
                            nzGhost
                            nzType="primary"
                            (click)="toggleTicketSearch(); $event.stopPropagation();"
                          >
                            <i nz-icon nzType="inbox" nzTheme="outline"></i> Ticket verknüpfen
                          </button>

                          <div class="ticketSearch" [ngClass]="{'showTicket': showTicketSearch}">
                            <app-global-search #globalSearchTICKETComponent [hideLinkTicketBtn]="true"
                                               [searchCategories]="'TICKETS'"
                                               (linkTicketToWorklog)="linkTicket($event)"
                                               [infoTooltip]="'Hier können Sie nach Ticketnamen und den zugehörigen Objektnamen suchen.'"
                                               [searchPlaceholder]="'Ticket suchen'"></app-global-search>
                          </div>
                        </div>
                      </ng-container>
                  </div>
                </div>
              </div>

              <button class="pull-right" nzSize="small" nz-button nzType="primary" *ngIf="!worklog.finalized" (click)="save()"
              >Speichern
              </button>
            </nz-tab>

            <nz-tab [nzTitle]="listHeader">
              <ng-template #listHeader>

                <nz-badge [nzCount]="worklog.checkLists.length +  worklog.consumed.length">
                  <span>Listen</span>
                </nz-badge>
              </ng-template>

              <div nz-col nzSpan="24" *ngIf="worklog.id">
                <nz-collapse class="m-r-15" [nzBordered]="true">
                  <nz-collapse-panel [nzActive]="worklog?.checkLists?.length > 0" [nzHeader]="headChecklist">
                    <ng-template #headChecklist>
                      Checklisten
                      <nz-badge class="pull-right m-l-20" [nzCount]="worklog?.checkLists?.length"></nz-badge>
                    </ng-template>
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <app-check-list-list [checkLists]="worklog?.checkLists"
                                             [worklog]="worklog"></app-check-list-list>
                      </div>
                    </div>
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <button *ngIf="worklog.object && !worklog.finalized"
                                class="pull-right m-t-15"
                                nz-button nzType="primary"
                                nzSize="small"
                                (click)="createNewChecklist()"
                        ><i nz-icon nzType="plus"></i>Hinzufügen
                        </button>
                      </div>
                    </div>
                  </nz-collapse-panel>

                  <nz-collapse-panel [nzActive]="worklog?.consumed?.length > 0" [nzHeader]="headConsumed">
                    <ng-template #headConsumed>
                      Verbrauchsmaterial
                      <nz-badge class="pull-right m-l-20" [nzCount]="worklog?.consumed?.length"></nz-badge>
                    </ng-template>
                    <nz-table [nzShowPagination]="true" nzPaginationType="small" nzSize="small" [nzPageSize]="9999"
                              #consumablesTable
                              [nzData]="worklog?.consumed">
                      <thead>
                      <tr>
                        <th nzWidth="200px">Name</th>
                        <th nzWidth="150px">Anzahl</th>
                        <th nzWidth="150px">Einheit</th>
                        <th *ngIf="!worklog.finalized" nzWidth="120px"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngIf="!worklog.finalized">
                        <td>
                          <nz-select [(ngModel)]="worklogConsumable.consumable"
                                     style="width: 100%;"
                                     nzMode="default"
                                     nzPlaceHolder="Bitte auswählen">
                            <nz-option *ngFor="let item of availableConsumables" [nzLabel]="item.name"
                                       [nzValue]="item"></nz-option>
                          </nz-select>
                        </td>
                        <td>
                          <input type="text" [min]="0" [(ngModel)]="worklogConsumable.number" nz-input
                                 placeholder="0">
                        </td>
                        <td>
                          {{ worklogConsumable?.consumable?.unit }}
                        </td>
                        <td>
                          <button [disabled]="!worklogConsumable.consumable" class="m-t-10" nz-button
                                  nzType="default"
                                  nzSize="small" nzShape="circle"
                                  nzType="primary" (click)="setWorklogConsumable()"><i nz-icon nzType="plus"></i>
                          </button>
                        </td>
                      </tr>

                      <tr *ngFor="let data of worklog?.consumed">
                        <td>
                          {{ data.name }}
                          <p class="m-b-0">
                            {{ data.description }}
                          </p>
                          <p class="m-b-0">
                            {{ data.Consumable_Ticket.createdAt | date:'dd.MM.y H:mm' }}
                          </p>
                        </td>
                        <td>
                          <input type="text" (ngModelChange)="data.Consumable_Ticket._isTouched = true" nz-input
                                 placeholder="0" [(ngModel)]="data.Consumable_Ticket.number"/>
                        </td>
                        <td>{{ data.unit }}</td>

                        <td *ngIf="!worklog.finalized">
                          <button *ngIf="data.Consumable_Ticket._isTouched" class="m-t-10 m-r-5" nz-button
                                  nzType="primary" nzSize="small" nzShape="circle"
                                  (click)="setWorklogConsumable(data.Consumable_Ticket)"><i nz-icon
                                                                                            nzType="save"></i>
                          </button>
                          <button class="m-t-10" nz-button nzType="default" nzShape="circle" nzSize="small" nzDanger
                                  (click)="deleteConsumable(data.Consumable_Ticket)"><i nz-icon nzType="delete"></i>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </nz-table>
                  </nz-collapse-panel>
                </nz-collapse>
              </div>
            </nz-tab>

            <nz-tab [nzTitle]="serviceHeader">
              <ng-template #serviceHeader>
                <nz-badge [nzCount]="worklog?.completedServices?.length">
                  <span>Leistungen</span>
                </nz-badge>
              </ng-template>
              <div nz-col nzSpan="24" *ngIf="worklog.id">


                <nz-table nzSize="small"  #completedServices nzTemplateMode
                >

                  <tbody class="box" cdkDropList (cdkDropListDropped)="drop($event)">
                  <tr *ngFor="let data of worklog.completedServices; let i = index" class="editable-row"
                      cdkDrag>
                    <td style="display: flex; align-items: center;">
                      <i cdkDragHandle nz-icon nzType="menu" nzTheme="outline"></i>
                      <input [disabled]="!!worklog.finalized" (ngModelChange)="isTouched = true" type="text"
                             nz-input
                             [(ngModel)]="data.name" style="margin-left: 10px;">
                    </td>
                    <td>
                      <button *ngIf="!worklog.finalized" (click)="deleteCservice(i); $event.stopPropagation()"
                              nzSize="small"
                              nz-button nzType="link"
                      ><i nz-icon nzType="delete"></i></button>
                    </td>
                  </tr>
                  </tbody>

                </nz-table>
                <div class="pull-right p-t-10">
                  <button *ngIf="!worklog.finalized" [disabled]="hidebtn()" nz-button nzSize="small"
                          (click)="saveCompletedServices()" nzType="primary">Speichern
                  </button>
                </div>

                <div class="p-t-10">
                  <button *ngIf="!worklog.finalized" nz-button nzSize="small" (click)="addOwnService()"
                          nzType="default"><i nz-icon nzType="plus"
                                              nzTheme="outline"></i>Hinzufügen
                  </button>
                </div>
              </div>
            </nz-tab>

            <nz-tab [nzTitle]="gpsHeader">
              <ng-template #gpsHeader>

                <nz-badge [nzCount]="worklog?.geolocations?.length">
                  <span>GPS-Protokolle</span>
                </nz-badge>
              </ng-template>
              <app-gps-list #gpsListComponent [worklog]="worklog"></app-gps-list>
            </nz-tab>

            <nz-tab [nzTitle]="fileHeader">
              <ng-template #fileHeader>

                <nz-badge [nzCount]="fileListComponent?.files?.length">
                  <span>Dateien</span>
                </nz-badge>
              </ng-template>

              <a *ngIf="worklog?.ticket_id && !worklog.finalized" (click)="openFileBrowser()">Bilder aus Ticket verknüpfen</a>

              <app-file-list #fileListComponent [worklogId]="worklog.id"
                             [uploadEnabled]="!worklog.finalized"></app-file-list>
              <p *ngIf="fileListComponent?.files?.length <= 0 && worklog?.finalized">Keine Dateien vorhanden</p>
            </nz-tab>


            <nz-tab [nzTitle]="headerSignature">
              <ng-template #headerSignature>

                <nz-badge [nzCount]="worklog?.signatures?.length">
                  <span>Unterschriften</span>
                </nz-badge>
              </ng-template>
              <div nz-row [nzGutter]="[16, 16]">
                <div class="inline-block" *ngFor="let signature of worklog?.signatures; let i = index">
                  <nz-card nzHoverable style="width:240px; margin-right: 10px;" [nzCover]="coverTemplate">
                    <nz-card-meta [nzTitle]="signature.name"
                                  [nzDescription]="signature.createdAt | date: 'dd.MM.yyyy HH:mm'"></nz-card-meta>
                    <button
                      *ngIf="!worklog.finalized"
                      nz-button
                      class="m-t-20 w-100"
                      nzDanger
                      nzType="link"
                      nzSize="small"

                      (click)="deleteSignature(i)"><i nz-icon nzType="delete"></i> Löschen
                    </button>
                  </nz-card>

                  <ng-template #coverTemplate>
                    <img alt="example" [src]="signature.canvas"/>
                  </ng-template>
                </div>
                <nz-card *ngIf="!worklog.finalized" nzHoverable
                         style="min-width:240px; min-height: 283px; margin-right: 10px; opacity: .69"
                         [nzCover]="icon"
                         (click)="createNewSignature()">
                  <ng-template #icon>
                    <i nz-icon nzType="plus" style="font-size: 80px; margin-top: 100px; color: #ffb100"></i>
                  </ng-template>
                </nz-card>
              </div>
            </nz-tab>

            <nz-tab [nzTitle]="linkHeader">
              <ng-template #linkHeader>

                <nz-badge [nzCount]="sharedWorklog?.length">
                  <span>Geteilte-Links</span>
                </nz-badge>
              </ng-template>
              <ng-container>

                <app-worklog-share-list #worklogShareListComponent [worklog]="worklog"></app-worklog-share-list>
              </ng-container>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </nz-card>


  </ng-container>
</nz-spin>
