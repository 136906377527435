<h2>LexOffice Rechnung erzeugen</h2>

<h5>Welche Informationen, sollen an LexOffice übermittelt werden?</h5>

<button (click)="addWorkTime()" nz-button nzType="default" class="m-r-5" ><span nz-icon nzType="plus"></span> Arbeitszeit</button>
<button (click)="addConsumables()" nz-button nzType="default" class="m-r-5" ><span nz-icon nzType="plus"></span> Verbrauchsmaterial</button>
<button (click)="addGPS()" nz-button nzType="default" class="m-r-5" ><span nz-icon nzType="plus"></span> GPS-Bemerkung</button>

<div class="invoice-preview m-t-20">
  <h4>Vorschau</h4>
  <nz-table nzSize="small" nzTemplateMode>
    <thead>
    <tr>
      <th>Produkt / Service</th>
      <th>Menge</th>
      <th>Einheit</th>
      <th>Einzelpreis (€)</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let lineItem of lxInvoice.lineItems; let i = index">

      <td>
        <input nz-input [(ngModel)]="lineItem.name">
        <textarea class="m-t-5" nz-input [(ngModel)]="lineItem.description" placeholder="Beschreibung"></textarea>
      </td>
      <td><input nz-input type="number" [(ngModel)]="lineItem.quantity"></td>
      <td><input nz-input [(ngModel)]="lineItem.unitName"></td>

      <td><input nz-input type="number" [(ngModel)]="lineItem.unitPrice.netAmount"></td>
      <td>
        <button (click)="cloneLineItem(i)" nz-button nzType="default" class="m-r-5" nzShape="circle"><span nz-icon nzType="copy"></span></button>
        <button (click)="deleteLineItem(i)" nz-button nzType="primary" nzDanger nzShape="circle"><span nz-icon nzType="delete"></span></button>

      </td>
    </tr>
    </tbody>
  </nz-table>
</div>



<div class="m-t-20">

  <button class="pull-right" nz-button nzType="primary" (click)="sendToLexoffice()">Rechnung erzeugen
  </button>
  <p>Die Rechnung wird als Entwurf in LexOffice erzeugt und kann anschließend direkt in LexOffice bearbeitet werden.</p>
</div>

