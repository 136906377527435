import {Component, Input, OnInit} from '@angular/core';
import {Absent, AbsentType, PublicHoliday} from '../../../../../../../database-models';
import {ApiService} from "../../../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import * as moment from "moment/moment";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {AbsentOverviewComponent} from "../absent-overview/absent-overview.component";


@Component({
  selector: 'app-absent',
  templateUrl: './absent.component.html',
  styleUrls: ['./absent.component.scss']
})
export class AbsentComponent implements OnInit {

  @Input() public userId: string;

  public userAbsents: Absent[];
  public absentTypes: AbsentType[];
  public absent: Absent;
  public holidays: PublicHoliday[] = [];

  public allHolidays: any = [];
  public holidayLabel: any = [];
  public rangeSelected: any = []
  public label: any = []
  public color: any = [];

  public title: string = 'Abwesenheit';
  public isLoading: boolean = false;
  private date: Date = new Date();
  public AU_Upload: NzUploadFile[] = [];

  public absentForm: FormGroup;

  constructor(
      public api: ApiService,
      private modalService: NzModalService,
      private modal: NzModalRef,
      public formBuilder: FormBuilder,
      private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.getAbsentTypes();

    if (this.userId) {
      this.getAbsentsByUser();
    }

    this.absent = {
      userId: this.userId
    } as Absent

    this.initForm();
  }

  public initForm(): void {
    this.absentForm = this.formBuilder.group(
        {
          daterange: [this.date, [Validators.required]],
          halfDay: [false, [Validators.required]],
          typeId: ['LEAVE'],
          note: [''],
        }
    );
  }

  public setAbsent(approved?: boolean, absentId?: string): void {
    if (approved) {
      this.isLoading = true;
      const toSend = this.absentForm.getRawValue();
      this.absent.approved = approved
      this.absent.id = absentId

      console.log('approved', this.absent)
      this.api.setAbsentApproved(this.absent).subscribe(value => {
        this.getAbsentsByUser();
        this.message.success('Wurde genehmigt');
        this.isLoading = false;
      });
    } else {
      this.isLoading = true;
      const toSend = this.absentForm.getRawValue();
      this.absent.halfDay = toSend.halfDay
      if (this.absent.halfDay) {
        this.absent.start = moment(toSend.daterange).format("YYYY-MM-DD");
        this.absent.end = this.absent.start;
      } else {
        this.absent.start = moment(toSend.daterange[0]).format("YYYY-MM-DD");
        this.absent.end = toSend.daterange[1] ? moment(toSend.daterange[1]).format("YYYY-MM-DD") : this.absent.start;
      }
      this.absent.absentTypeId = toSend.typeId
      this.absent.note = toSend.note
      this.absent.approved = true
      this.absent.userId = this.userId

      this.api.setAbsent(this.absent).subscribe(value => {
        this.userAbsents.push(value);
        this.getAbsentsByUser();
        this.message.success('Abwesenheit wurde gespeichert, und wurde in der Historie hinterlegt');
        this.isLoading = false;
        this.initForm();
      });
    }
  };

  public async getAbsentsByUser() {
    this.isLoading = true
    let value = await this.api.getAbsentsByUser(this.userId).toPromise();
    this.userAbsents = value
    const invalids: any = [];
    const labels: any = [];
    let colors: any = [];
    for (const absent of this.userAbsents) {
      invalids.push({start: absent.start, end: absent.end});
      labels.push({
        start: absent.start,
        end: absent.end,
        text: absent.approved ? ((absent.halfDay ? '&#189; ' : '&#10003; ') + absent.absentType.name) : (absent.halfDay ? '&#189; ' : '') + absent.absentType.name,
        textColor: 'rgba(86,86,86,0.8)'
      });
      colors.push({
        start: absent.start,
        end: absent.end,
        background: absent.absentType.lightcolor,
        cellCssClass: absent.approved ? '': 'absent-stripes-bg'
      })
    }
    this.rangeSelected = invalids
    this.label = labels
    this.color = colors


    let holidays = await this.api.getPublicHolidays().toPromise();
    this.holidays = holidays;
    const allHoliday: any = [];
    const holidayLabel: any = [];
    for (const holiday of this.holidays) {
      allHoliday.push({
        start: holiday.date,
        end: holiday.date,
        background: '#ffd9e1'
      });

      holidayLabel.push({
        start: holiday.date,
        end: holiday.date,
        text: holiday.name,
        textColor: 'rgba(86,86,86,0.8)'
      });
    }
    this.allHolidays = allHoliday;
    this.holidayLabel = holidayLabel;
    this.color = this.color.concat(this.allHolidays)
    this.rangeSelected = this.rangeSelected.concat(this.allHolidays);
    this.label = this.label.concat(this.holidayLabel);

    this.isLoading = false
  }

  public getAbsentTypes(): void {
    this.isLoading = true;
    this.api.getAbsentTypes().subscribe(types => {
      this.absentTypes = types;
      this.isLoading = false;
    });
  };

  public deleteAbsent(id: string, index: number): void {
    this.modalService.warning({
      nzTitle: '<i>Abwesenheit löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie den Abwesenheit Eintrag löschen möchten?',
      nzOnOk: () => this.delete(id, index),
      nzCancelText: 'Abbrechen'
    });
  }

  public delete(id: string, index: number): void {
    this.api.deleteAbsentByUser(id).subscribe(value => {
      this.message.info('Eintrag wurde gelöscht')
      this.userAbsents.splice(index, 1);
      this.getAbsentsByUser();
    });
  }

  public absentOverview(): void {
    const modal = this.modalService.create({
      nzContent: AbsentOverviewComponent,
      nzComponentParams: {
        color: this.color,
        label: this.label,
        rangeSelected: this.rangeSelected
      },
      nzFooter: null,
      nzWidth: 1400
    });
    modal.afterClose.subscribe((data: any) => {});
  }

  public calculateDays(start, end) {
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');

    const daysDifference = endDate.diff(startDate, 'days') + 1;

    return daysDifference;
  }

  public close(): void {
    this.modal.destroy();
  }
}
