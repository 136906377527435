import {Component, Input, OnInit} from '@angular/core';
import {File} from "../../../../../../database-models";
import {ApiService} from "../../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.css']
})
export class EditFileComponent implements OnInit {
  
  @Input() public file: File
  public suffix: string = '';
  public name: string = '';

  public fileForm: FormGroup

  constructor(
      public api: ApiService,
      public formBuilder: FormBuilder,
      private modalService: NzModalService,
      private modal: NzModalRef,
      private message: NzMessageService
  ) { }

  ngOnInit(): void {
    if (this.file) {
      this.split();
      this.fileForm = this.formBuilder.group({
        name: [this.name, [Validators.required]],
        description: [this.file.description],
        onlyManager: [this.file.onlyManager]
      })
    } else {
      this.fileForm = this.formBuilder.group({
        name: [null],
        description: [null],
        onlyManager: [false]
      })
    }
  }

  public split(): void {
    if (this.file && this.file.name) {
      const lastDotIndex = this.file.name.lastIndexOf('.');

      if (lastDotIndex !== -1) {
        this.name = this.file.name.substring(0, lastDotIndex);
        this.suffix = this.file.name.substring(lastDotIndex + 1);
      } else {
        this.name = this.file.name;
        this.suffix = '';
      }
    } else {
      this.name = '';
      this.suffix = '';
    }
  }

  public save(): void {
    const toSend = this.fileForm.getRawValue();
    const newFileName = toSend.name + '.' + this.suffix;

    this.file.name = newFileName;
    this.file.description = toSend.description;
    this.file.onlyManager = toSend.onlyManager;

    this.api.updateFile(this.file).subscribe(value => {
      this.message.success('Erfolgreich aktualisiert')
      this.modal.close();
    });
  }

  public deleteFile(id: string): void {
    this.modalService.confirm({
      nzTitle: '<i>Datei löschen?</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Datei unwiderruflich löschen möchten?',
      nzOnOk: () => this.delete(id),
    });
  }

  private delete(id: string): void {
    this.api.deleteFile(id).subscribe((file: File) => {
      this.message.success('Datei wurde erfolgreich gelöscht');
      this.modal.close(id)
    });
  }

  public close(): void {
    this.modal.destroy();
  }
}
