import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from '../shared.module';

import {HeaderComponent} from './header/header.component';
import {SearchComponent} from './search/search.component';
import {QuickViewComponent} from './quick-view/quick-view.component';
import {SideNavComponent} from './side-nav/side-nav.component';
import {FooterComponent} from './footer/footer.component';

import {SideNavDirective} from '../directives/side-nav.directive';
import {ThemeConstantService} from '../services/theme-constant.service';
import {AnQrcodeModule} from 'an-qrcode';
import { SideNavHausverwaltungComponent } from './side-nav-hausverwaltung/side-nav-hausverwaltung.component';
import {HeaderHausverwaltungComponent} from './header-hausverwaltung/header-hausverwaltung.component';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzDividerModule} from "ng-zorro-antd/divider";
import { AppversionLowerExceptionComponent } from './appversion-lower-exception/appversion-lower-exception.component';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzProgressModule} from "ng-zorro-antd/progress";
import {TasksModule} from "../../tasks/tasks.module";

@NgModule({
  exports: [
    HeaderComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    SideNavHausverwaltungComponent,
    HeaderHausverwaltungComponent
  ],
	imports: [
		RouterModule,
		SharedModule,
		AnQrcodeModule,
		NzAvatarModule,
		NzDropDownModule,
		NzListModule,
		NzIconModule,
		NzBadgeModule,
		NzButtonModule,
		NzDrawerModule,
		NzInputModule,
		NzGridModule,
		NzDividerModule,
		NzCardModule,
		NzProgressModule,
		TasksModule
	],
  declarations: [
    HeaderComponent,
    HeaderHausverwaltungComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    SideNavHausverwaltungComponent,
    AppversionLowerExceptionComponent
  ],
  providers: [
    ThemeConstantService
  ]
})

export class TemplateModule {
}
