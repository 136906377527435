import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-absent-overview',
  templateUrl: './absent-overview.component.html',
  styleUrls: ['./absent-overview.component.css']
})
export class AbsentOverviewComponent implements OnInit {

  @Input() public label: any = [];
  @Input() public color: any = [];
  @Input() public rangeSelected: any = [];

  constructor(
  ) { }

  ngOnInit(): void { }
}
