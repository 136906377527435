import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Router} from '@angular/router';
import {LexofficeService} from '../services/lexoffice.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private notification: NzNotificationService,
    private router: Router,
    public lexofficeService: LexofficeService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        console.log(request);

        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('client side error');
        } else {
          console.log('server side error');
        }

        if (!request.url.includes('lexoffice')) {

          if (error.status === 401 && error.error.logout) {
            this.authService.logout(true);
          }
          if (error.error?.error === 'api key missing' && error.status === 403) {
            this.authService.logout(true);
          }
        } else {
          if (error.status === 401) {
            this.notification.create(
              'error',
              'LexOffice',
              'Die Verbindung zu LexOffice ist abgelaufen und muss neu eingerichtet werden.'
            );
            this.lexofficeService.startOauth()
          }
        }

        throw error;
      })
    )
  }
}
