import {HostListener, Injectable, OnInit} from '@angular/core';
import * as moment from "moment/moment";
import {
  PublicHoliday,
  TimeTrackingDays,
  TimeTrackingTimeStamps,
  TimeTrackingType,
  TrackingAction,
  User
} from '../../../../../database-models';
import {BehaviorSubject} from "rxjs";
import {ApiService} from "../../services/api.service";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class TimetrackingService implements OnInit {

  public locked: boolean = false;
  public userTimeStampsLoading: boolean = false;

  public dutyTime: BehaviorSubject<any> = new BehaviorSubject<any>('00:00:00');
  public breakTime: BehaviorSubject<any> = new BehaviorSubject<any>('00:00:00');

  private dutyTimeSeconds = 0;
  private breakTimeSeconds = 0;

  private timer: any;

  public timetrackingTypes: TimeTrackingType[] = [];
  public timeStamps: TimeTrackingTimeStamps[] = [];
  public currentUser: User;

  constructor(
      public api: ApiService,
      private authService: AuthenticationService,
  ) {
  }


  async ngOnInit(): Promise<void> {
  }

  async load(): Promise<void> {
    this.api.getTimetrackingTypes().subscribe(types => {
      this.timetrackingTypes = types;
    });

    this.getEmployeeTimestamps()
    this.updateTimesView();
    await this.startTimer();
    this.authService.currentUser.subscribe(value => {
      this.currentUser = value;
    });
  }

  private async calcTimes(): Promise<any> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < this.timeStamps.length; i++) {
        let thisItem = this.timeStamps[i];
        let nextItem = this.timeStamps[i + 1];

        if (nextItem) {
            thisItem._average = moment(nextItem?.timestamp).diff(thisItem?.timestamp) / 1000;
          } else {
            thisItem._average = moment().diff(thisItem?.timestamp) / 1000;
          }

      }

      // Calc Duty Time
      const average = this.getSumAverage('KOMMEN');
      this.dutyTimeSeconds = average;

      // Calc Break Time
      const averageBreak = this.getSumAverage('PAUSE');
      this.breakTimeSeconds = averageBreak;

      resolve(true);
    });
  }

  private getSumAverage(type: TrackingAction): number {
    const stampsOfType = this.timeStamps.filter(stamp => stamp?.action === type);
    const sum = stampsOfType.reduce((partialSum, a) => partialSum + (a._average || 0), 0);
    return sum;
  }
  // Converts the _averageTime from ms to humanreadable
  public convertTime(seconds: number, zero: boolean = false): string {
    if (!seconds) {
      return zero ? '00:00' : null;
    }
    let inputNegative = false;
    if (seconds < 0) {
      inputNegative = true;
      seconds = seconds*-1;
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - (hours * 3600)) / 60);


    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return (inputNegative ? '-' : '') + `${formattedHours}:${formattedMinutes}`;
  }

  public timestampValidator(valiDay: TimeTrackingDays): TimeTrackingDays {
    valiDay._errorCount = 0;
    if (valiDay.timetracking_timestamps && valiDay.timetracking_timestamps.length > 0) {
      // Prüfen auf KOMMEN an erster Stelle
      if (valiDay.timetracking_timestamps[0].action !== 'KOMMEN') {
        valiDay._errorCount++;
        valiDay.timetracking_timestamps[0]._error = {
          reason: 'Der erste Zeiteintrag muss KOMMEN sein',
        };
      }

      // Prüfen auf DOPPELTE Stempel
      for (let i = 0; i < valiDay.timetracking_timestamps.length; i++) {
        let thisItem = valiDay.timetracking_timestamps[i];
        let nextItem = valiDay.timetracking_timestamps[i+1];
        if (nextItem) {
          if (thisItem.action === nextItem.action && thisItem.action !== 'KOMMEN') {
            valiDay._errorCount++;
            valiDay.timetracking_timestamps[i+1]._error = {
              reason: 'Doppelte Stempelung',
            };
          }
        }
      }

      // Prüfen auf GEHEN an letzter Stelle

      //Datum vom letzten zeiteintrag
      const lastTimestamp = valiDay.timetracking_timestamps[valiDay.timetracking_timestamps.length - 1];
      const lastTimestampDate = moment(lastTimestamp.timestamp);

      // datum vom tag
      const currentDate = moment();

      if (!lastTimestampDate.isSame(currentDate, 'day')) {
        if(lastTimestamp.action !== 'GEHEN') {
          valiDay._errorCount++;
          lastTimestamp._error = {
            reason: 'Der letzte Zeiteintrag muss GEHEN sein'
          };
        }
      }
    }
    return valiDay;
  }

  public getLastStampOfDay(day: TimeTrackingDays): TimeTrackingTimeStamps {
    if (day.timetracking_timestamps && day.timetracking_timestamps.length > 0) {
      return day.timetracking_timestamps[day.timetracking_timestamps.length - 1];
    } else {
      return null;
    }
  }

  public combineErrors(dayErros: TimeTrackingDays, asArray: boolean = false): string | string[] {
    const reasons = dayErros.timetracking_timestamps
        .filter(i => i._error?.reason)
        .map(i => i._error?.reason);
    if (asArray) {
      return reasons;
    } else {
      return reasons.join('\n')
    }

  }

  public isWeekend(day: TimeTrackingDays): boolean {
    const date = new Date(day.date);
    return date.getDay() === 6 || date.getDay() === 0;
  }

  public bundeslaender(holiday: PublicHoliday): string[] {
    const mapping = {
      all_states: 'Alle Bundesländer',
      bb: 'Brandenburg',
      be: 'Berlin',
      bw: 'Baden-Württemberg',
      by: 'Bayern',
      hb: 'Bremen',
      he: 'Hessen',
      hh: 'Hamburg',
      mv: 'Mecklenburg-Vorpommern',
      ni: 'Niedersachsen',
      nw: 'Nordrhein-Westfalen',
      rp: 'Rheinland-Pfalz',
      sh: 'Schleswig-Holstein',
      sl: 'Saarland',
      sn: 'Sachsen',
      st: 'Sachsen-Anhalt',
      th: 'Thüringen'
    };

    const holidayNames: string[] = [];
    if (holiday.all_states) {
      holidayNames.push('Alle Bundesländer');
    } else {
      for (const key in holiday) {
        if ((typeof holiday[key] == 'boolean') && holiday[key]) {
          holidayNames.push(mapping[key]);
        }
      }
    }
    return holidayNames;
  }


  // ************ TRIGGER FUNCTIONS **************

  public async stamp(action: TrackingAction, type?: TimeTrackingType): Promise<void> {

    this.lockForSeconds();
    const newStamp: TimeTrackingTimeStamps = {
      action: action,
      type: type?.id,
      timestamp: moment().toISOString(),
      user_id: this.currentUser.id
    } as TimeTrackingTimeStamps;

    await this.api.setEmployeeTimestamp(newStamp).subscribe((result: TimeTrackingTimeStamps[]) => {
      console.log(this.timeStamps);
      this.getEmployeeTimestamps();
    }, error => {

    });
  }

  private async startTimer(): Promise<void> {
    this.stopTimer();
    await this.calcTimes();
    if (!this.timer) {
      // Start only if on duty
      if (this.getLastAction() && this.getLastAction() !== 'GEHEN') {
        this.timer = setInterval(() => {
          if (this.getLastAction() === 'KOMMEN') {
            this.dutyTimeSeconds++;
          }
          if (this.getLastAction() === 'PAUSE') {
            this.breakTimeSeconds++;
          }

          this.updateTimesView();
        }, 1000);
      }
    }

  }

  public getLastAction(): TrackingAction {
    const lastEntry: TimeTrackingTimeStamps = this.timeStamps[this.timeStamps.length - 1];
    return lastEntry?.action;
  }

  public getLastType(): TimeTrackingType {
    const lastEntry: TimeTrackingTimeStamps = this.timeStamps[this.timeStamps.length - 1];
    return lastEntry?.timetracking_type;
  }

  private updateTimesView(): void {
    const dutyTimeSecondsFormatted = moment.utc(this.dutyTimeSeconds * 1000).format('HH:mm:ss');
    this.dutyTime.next(dutyTimeSecondsFormatted);

    const breakTimeSecondsFormatted = moment.utc(this.breakTimeSeconds * 1000).format('HH:mm:ss');
    this.breakTime.next(breakTimeSecondsFormatted);
  }

  private stopTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  private lockForSeconds(): void {
    console.log('lock');
    this.locked = true;
    setTimeout(() => {
      this.locked = false;
      console.log('unlock');
    }, 1000);
  }

  public getEmployeeTimestamps(): void {
    this.userTimeStampsLoading = true
    this.api.getTimeStamps().subscribe(async value => {
      this.timeStamps = value;
      await this.calcTimes();
      this.updateTimesView();
      await this.startTimer();
      this.userTimeStampsLoading = false;
    }, error => {
      console.log(error);
      this.userTimeStampsLoading = false;
    });
  }
}
