import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  Absent,
  ActivityLog,
  Application,
  Appuser,
  Appuser_Users_Objects,
  Company, Consumable,
  Contact, ContactPerson, Counter, CounterStatus,
  Customer, File,
  FreeTextNote,
  Hausverwaltung,
  Hausverwaltungsansprechpartner,
  News,
  Notification,
  NotificationPriority,
  Object,
  ObjectGroup,
  ObjectNote,
  PropertyManagement,
  PropertyManagementRequest, Room,
  SepaMandat,
  Service, ServiceCatalog, ServiceCatalogItem, Share_Worklog_Users,
  Task,
  Team,
  Ticket,
  TicketBulUpdate,
  TicketNote, TimeTrackingDays, TimeTrackingMonth, TimeTrackingTimeStamps,
  User, Users_Objects, Wasteplan, WorkLog,
} from '../../../../database-models';
import {environment} from '../../environments/environment';
import {AuthenticationService} from '../shared/services/authentication.service';
import {CalendarConfig} from '../shared/interfaces/calendar-config';
import {InvoiceCreate, OptionalFilters, PagingParameters} from '../../../../Shared/lexoffice-client-js-main/src';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiURL = environment.apiURL;
  public headers;
  private apiKey: any;

  constructor(
    public http: HttpClient,
    public autenticationService: AuthenticationService
  ) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    this.autenticationService.session.subscribe((session) => {
      if (session) {
        this.apiKey = session;
        this.headers = this.headers
          .set('apikey', session);
      }
    });
  }


  public getNews(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/news', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/news/' + id, {headers: this.headers});
    }
  }

  public postNews(data: News, sendPush: boolean): Observable<any> {
    const headers = this.headers
      .set('push', sendPush ? '1' : '0');
    return this.http.post(this.apiURL + '/admin/news/', data, {headers});
  }

  public getEmployeeNews(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/employee-news', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/employee-news/' + id, {
        headers: this.headers,
      });
    }
  }

  public postEmployeeNews(data: News, sendPush: boolean): Observable<any> {
    const headers = this.headers
      .set('push', sendPush ? '1' : '0');
    return this.http.post(this.apiURL + '/admin/employee-news/', data, {
      headers,
    });
  }

  public getCompanyData(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/mycompany/', {headers: this.headers});
  }

  public setCompanyData(data: Company | any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/mycompany/', data, {headers: this.headers});
  }

  public setCompanyCalendarConfig(data: CalendarConfig): Observable<any> {
    return this.http.post(this.apiURL + '/admin/mycompany/calendar-config', data, {headers: this.headers});
  }

  public getTicketsGroupedByState(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/all/state/', {headers: this.headers});
  }

  public getTicketsByState(stateId: string, httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/state/' + stateId, {
      headers: this.headers,
      params: httpParams,
    });
  }

  public getTicket(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/' + id, {headers: this.headers});
  }

  public getSharedTicket(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/' + id + '/shared-ticket', {headers: this.headers});
  }

  public getSharedWorklog(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/shared-worklog/' + id, {headers: this.headers});
  }


  public getTask(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/task/', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/task/' + id, {headers: this.headers});
    }
  }

  public getFilteredTask(filter?: any): Observable<any> {

    return this.http.get(this.apiURL + '/admin/task/', {headers: this.headers, params: filter});
  }

  public globalSearch(httpParams?: HttpParams): Observable<any> {
    let headers = this.headers;
    return this.http.get(this.apiURL + '/gsearch/', {headers, params: httpParams});
  }

  public getMyTask(id?: string): Observable<any> {
    return this.http.get(this.apiURL + '/employee/task/' + id, {headers: this.headers});
  }

  public setTask(data: Task | any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/task/', data, {headers: this.headers});
  }

  public deleteTicketTaskRelation(taskId: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/ticket/deleteRelation/task/' + taskId, {headers: this.headers});
  }

  public deleteTaskTicketRelation(taskId: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/task/' + taskId + '/deleteRelation/ticket/', {headers: this.headers});
  }

  public setBulkTask(data: Task | any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/task/bulk', data, {headers: this.headers});
  }

  public sendTaskNotification(data: Task | any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/task/notify', data, {headers: this.headers});
  }

  public deleteTask(id: string | any): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/task/' + id, {headers: this.headers});
  }

  public getTaskUserResources(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/task/user-resources', {
      headers: this.headers,
    });
  }

  public getTaskTeamResources(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/task/team-resources', {
      headers: this.headers,
    });
  }

  public getStates(): Observable<any> {
    return this.http.get(this.apiURL + '/state/', {headers: this.headers});
  }

  public getPriorities(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/priority/', {headers: this.headers});
  }

  public getTicketImage(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/' + id + '/image', {
      headers: this.headers,
    });
  }

  public deleteImage(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/image/' + id, {headers: this.headers});
  }

  public getTicketNotes(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/' + id + '/note', {
      headers: this.headers,
    });
  }

  public createTicketNote(ticketId: string, data: TicketNote): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/ticket/' + ticketId + '/note',
      data,
      {headers: this.headers}
    );
  }

  public deleteTicketNotes(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/note/' + id, {headers: this.headers});
  }

  public createTicket(data: Ticket): Observable<any> {
    return this.http.post(this.apiURL + '/admin/ticket/', data, {headers: this.headers});
  }

  public updateTicketField(ticket: Ticket, field: string, value: any, doUntilAllDay?: boolean): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/ticket/' + ticket.id + '/field/' + field,
      {value, doUntilAllDay},
      {headers: this.headers}
    );
  }

  public updateTicketBulk(update: TicketBulUpdate): Observable<any> {
    return this.http.post(this.apiURL + '/admin/ticket/bulk', update, {
      headers: this.headers,
    });
  }

  public updateWorkLogBulk(update: TicketBulUpdate): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/bulk', update, {
      headers: this.headers,
    });
  }

  public assignTicket(data: Ticket): Observable<any> {
    return this.http.post(this.apiURL + '/admin/ticket/assign', data, {
      headers: this.headers,
    });
  }

  public assignTicketEmployee(data: Ticket): Observable<any> {
    return this.http.post(this.apiURL + '/admin/ticket/assignemployee', data, {
      headers: this.headers,
    });
  }

  public getObjects(
    id?: string,
    includearchived: boolean = false
  ): Observable<any> {
    if (!id) {
      let headers: HttpHeaders = this.headers;

      if (includearchived) {
        headers = headers.append('includearchived', 'true');
      }

      return this.http.get(this.apiURL + '/admin/object', {headers});
    } else {
      return this.http.get(this.apiURL + '/admin/object/' + id, {headers: this.headers});
    }
  }

  public getObjectPrintData(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + id + '/print-data', {
      headers: this.headers,
    });
  }

  public createObject(data: Object | Object[]): Observable<any> {
    return this.http.post(this.apiURL + '/admin/object', data, {headers: this.headers});
  }

  public updateObject(data: Object): Observable<any> {
    return this.http.put(this.apiURL + '/admin/object/' + data.id, data, {headers: this.headers});
  }

  public setCustomer(data: Customer): Observable<any> {
    return this.http.post(this.apiURL + '/admin/customer', data, {headers: this.headers});
  }

  public getUsers(id?: string, params?: any): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/user/', {headers: this.headers, params});
    } else {
      return this.http.get(this.apiURL + '/admin/user/' + id, {headers: this.headers});
    }
  }


  public setUser(data: User): Observable<any> {
    return this.http.post(this.apiURL + '/admin/user/', data, {headers: this.headers});
  }

  public getAppusers(id?: string, params?: any): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/appuser/', {headers: this.headers, params});
    } else {
      return this.http.get(this.apiURL + '/admin/appuser/' + id, {headers: this.headers, params});
    }
  }

  public getObjectAppusers(objectId: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/object/' + objectId + '/appusers',
      {headers: this.headers}
    );
  }

  public setShareWorklogUsers(worklogId: string, data: any[]): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/share_worklog_users/' + worklogId, data, {headers: this.headers});
  }

  public getOpenTicketCount(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/all/open', {headers: this.headers});
  }

  public getTicketsByUser(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/mytickets/', {headers: this.headers});
  }

  public getTicketsByFilters(
    searchQuery: string,
    selectedFilters: string[]
  ): Observable<any> {
    const query = {searchQuery, selectedFilters};

    return this.http.post(this.apiURL + '/admin/ticket/search/', query, {
      headers: this.headers,
    });
  }

  public changePassword(data: any, id?: String) {
    if (!id) {
      return this.http.post(
        this.apiURL + '/admin/user/me/change-password',
        data,
        {headers: this.headers}
      );
    } else {
      return this.http.post(
        this.apiURL + '/admin/user/change-password/' + id,
        data,
        {headers: this.headers}
      );
    }
  }

  public sendMail(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/user/mail/', data, {headers: this.headers});
  }

  public getEmployee(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/employee/', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/employee/' + id, {headers: this.headers});
    }
  }

  public getAllUsers(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/all-users', {headers: this.headers});
  }

  public deleteEmployee(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/employee/' + id, {headers: this.headers});
  }

  public deleteTicket(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/ticket/' + id, {headers: this.headers});
  }

  public deleteObject(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/object/' + id, {headers: this.headers});
  }

  public archiveObject(id: string): Observable<any> {
    return this.http.put(this.apiURL + '/admin/object/' + id + '/archive', {id}, {headers: this.headers});
  }

  public deleteNews(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/news/' + id, {headers: this.headers});
  }

  public deleteEmployeeNews(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/employee-news/' + id, {
      headers: this.headers,
    });
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/user/' + id, {headers: this.headers});
  }

  public deleteLogo(): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/mycompany/logo', {headers: this.headers});
  }

  public getPropertyManagements(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/propertymanagement/', {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.apiURL + '/admin/propertymanagement/' + id, {
        headers: this.headers,
      });
    }
  }

  public setPropertyManagement(data: Hausverwaltung): Observable<any> {
    return this.http.post(this.apiURL + '/admin/propertymanagement/', data, {
      headers: this.headers,
    });
  }

  public deletePropertyManagement(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/propertymanagement/' + id, {
      headers: this.headers,
    });
  }

  public getContactsByPropertyManagement(id: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/propertymanagement/' + id + '/propertymanagementcontact', {headers: this.headers}
    );
  }

  public getMyPropertyManagements(): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/my-property-managements/',
      {headers: this.headers}
    );
  }

  public getPropertyManagementContactPerson(id: string): Observable<any> {
    return this.http.get(
        this.apiURL + '/admin/property-management/'  + id + '/contactperson',
        {headers: this.headers}
    );
  }

  public deletePropertyManagementContact(id: string): Observable<any> {
    return this.http.delete(
      this.apiURL + '/admin/propertymanagementcontact/' + id,
      {headers: this.headers}
    );
  }

  public setPropertyManagementContact(
    data: Hausverwaltungsansprechpartner
  ): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/propertymanagementcontact/',
      data,
      {headers: this.headers}
    );
  }

  public getTeam(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/team/', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/team/' + id, {headers: this.headers});
    }
  }

  public deleteTeam(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/team/' + id, {headers: this.headers});
  }

  public setTeam(data: Team): Observable<any> {
    return this.http.post(this.apiURL + '/admin/team/', data, {headers: this.headers});
  }

  public getWorklogs(httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/worklog', {headers: this.headers, params: httpParams});
  }

  public getWorklog(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/worklog/' + id, {headers: this.headers});
  }

  public getTaskWorklogs(taskId: any): Observable<any> {
    return this.http.get(this.apiURL + '/task/' + taskId + '/worklog', {
      headers: this.headers,
    });
  }

  public setWorklog(data: WorkLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/', data, {headers: this.headers});
  }

  public setFinal(data: WorkLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/', data, {headers: this.headers});
  }

  public deleteWorklog(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/worklog/' + id, {headers: this.headers});
  }

  public getWorklogsByObject(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + id + '/worklogs', {headers: this.headers});
  }

  public getObjectGroup(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/objectgroup/', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/objectgroup/' + id, {
        headers: this.headers,
      });
    }
  }

  public getObjectsWithNoGroup(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/withoutgroup/', {
      headers: this.headers,
    });
  }

  public setObjectGroup(data?: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/objectgroup/', data, {
      headers: this.headers,
    });
  }

  public deleteObjectGroup(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/objectgroup/' + id, {
      headers: this.headers,
    });
  }

  public getContactDetails(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/contact/details/' + id, {
      headers: this.headers,
    });
  }

  public getContactsbyObject(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/contact/' + id, {headers: this.headers});
  }

  public getContactsbyCustomer(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/customers/customer_contact/' + id, {headers: this.headers});
  }

  public getCustomerbyObject(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/customers/object_customer/' + id, {headers: this.headers});
  }

  public getTicketsByObject(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + id + '/tickets', {headers: this.headers});
  }

  public deleteObjectContactAssociation(object_id: string, contact_id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/object-contact-association/' + object_id + '/' + contact_id,
      {headers: this.headers}
    );
  }

  public deleteContactCustomerAssociation(contactId: string, customerId: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/customers/object-customer-association/contact/' + contactId + '/customer/' + customerId,
        {headers: this.headers}
    );
  }

  public deleteContact(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/' + id, {headers: this.headers});
  }

  public setContact(data: Contact): Observable<any> {
    return this.http.post(this.apiURL + '/admin/contact', data, {headers: this.headers});
  }

  public setContactPerson(data: ContactPerson): Observable<any> {
    return this.http.post(this.apiURL + '/admin/contact-person', data, {headers: this.headers});
  }

  public updateContact(data: Contact): Observable<any> {
    return this.http.put(this.apiURL + '/admin/contact/' + data.id, data, {headers: this.headers});
  }

  public setObjectsOfContacts(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/contact/obj', data, {
      headers: this.headers,
    });
  }

  public setCustomerOfContacts(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/customer/contact/relation', data, {
      headers: this.headers,
    });
  }

  public setObjectsOfCustomers(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/customer/object', data, {
      headers: this.headers,
    });
  }

  public setAppuserUsersObjects(data?: Appuser_Users_Objects): Observable<any> {
    return this.http.post(this.apiURL + '/admin/appuser-users-objects', data, {
      headers: this.headers
    });
  }

  public setConsumableBlocked(data: Consumable): Observable<any> {
    return this.http.post(this.apiURL + '/admin/consumable/block', data, {
      headers: this.headers,
    });
  }

  public getContacts(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/contact/', {headers: this.headers});
  }

  public getContactTypes(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/contact-types/', {headers: this.headers});
  }

  public getCustomer(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/customer/', {headers: this.headers});
  }

  public deleteCustomer(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/customer/' + id, {headers: this.headers});
  }

  public deleteFile(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/file/' + id, {headers: this.headers});
  }

  public deleteSharedTicket(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/shared-ticket/' + id, {headers: this.headers});
  }

  public deleteSharedWorkLog(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/shared-worklog/' + id, {headers: this.headers});
  }

  public downloadFile(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/file/' + id + '/download', {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  public downloadInvoice(lexInvoiceId: string): Observable<any> {
    return this.http.get(this.apiURL + '/file/lexinvoice/' + lexInvoiceId, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  public downloadImage(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/image/' + id + '/download', {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  public getObjectFiles(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + id + '/file', {
      headers: this.headers,
    });
  }

  public getObjectFreeTextNotes(id: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/object/' + id + '/freetextnotes',
      {headers: this.headers}
    );
  }

  public getTicketFiles(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/' + id + '/file', {
      headers: this.headers,
    });
  }

  public setFreeTextNote(data: FreeTextNote): Observable<any> {
    return this.http.post(this.apiURL + '/admin/freetextnote', data, {
      headers: this.headers,
    });
  }

  public createApplication(data: Application): Observable<any> {
    return this.http.post(this.apiURL + '/application/', data, {headers: this.headers});
  }

  public createTicketsCalendar(time: Date): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket/calendar/' + time, {
      headers: this.headers,
    });
  }

  public getInvoice(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/shared/invoice/' + id, {headers: this.headers});
  }

  public getServices(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/service/' + id, {headers: this.headers});
  }

  public setServices(objectId: string, data: Service[]): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/object/' + objectId + '/service',
      data,
      {headers: this.headers}
    );
  }

  public getServiceTemplates(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/serviceTemplate/', {headers: this.headers});
  }

  public getAllServices(): Observable<any> {
    return this.http.get(this.apiURL + '/service/', {headers: this.headers});
  }

  public getDetails(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/detail/' + id, {headers: this.headers});
  }

  public setDetails(objectId: string, data: Service[]): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/object/' + objectId + '/detail',
      data,
      {headers: this.headers}
    );
  }

  public getAvailableDetails(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/details', {headers: this.headers});
  }

  public createCompanyPropertyManagement(
    data: PropertyManagement
  ): Observable<any> {
    return this.http.post(this.apiURL + '/company-property-management/', data, {
      headers: this.headers,
    });
  }

  public updateObjectField(
    object: Object,
    field: string,
    value: any
  ): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/object/' + object.id + '/field/' + field,
      {value},
      {headers: this.headers}
    );
  }

  public getObjectNotes(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + id + '/note', {
      headers: this.headers,
    });
  }

  public getRoomNotes(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/room/' + id + '/note', {
      headers: this.headers,
    });
  }

  public createObjectNote(data: any): Observable<any> {
    // Important because not JSON
    const headers = new HttpHeaders().set('apikey', this.apiKey);

    return this.http.post(this.apiURL + '/admin/object/note', data, {headers});
  }

  public deleteObjectNote(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/objectnote/' + id, {
      headers: this.headers,
    });
  }

  public updateReadNotification(data: Notification | {id: string}): Observable<any> {
    return this.http.post(this.apiURL + '/notification/read', data, {
      headers: this.headers,
    });
  }

  public getNotification(id?: string): Observable<any> {
    if (id) {
      return this.http.get(this.apiURL + '/notification/' + id, {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/notification', {headers: this.headers});
    }
  }

  public getNotificationPriorities(id?: string): Observable<any> {
    if (id) {
      return this.http.get(this.apiURL + '/admin/notification-priority/' + id, {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.apiURL + '/admin/notification-priority', {
        headers: this.headers,
      });
    }
  }

  public deleteNotificationPriority(id: string): Observable<any> {
    return this.http.delete(
      this.apiURL + '/admin/notification-priority/' + id,
      {headers: this.headers}
    );
  }

  public setNotificationPriority(data: NotificationPriority): Observable<any> {
    return this.http.post(this.apiURL + '/admin/notification-priority/', data, {
      headers: this.headers,
    });
  }

  public getContributorForTicket(ticketId: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/contributor/ticket/me/' + ticketId,
      {headers: this.headers}
    );
  }

  public updateContributorForTicket(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/contributor/', data, {
      headers: this.headers,
    });
  }

  public getTicketContributors(ticketId: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/contributor/ticket/' + ticketId,
      {headers: this.headers}
    );
  }

  public deleteContributor(id: string): Observable<any> {
    return this.http.delete(
      this.apiURL + '/admin/contributor/' + id,
      {headers: this.headers}
    );
  }

  public getConsumables(id?: string, params?: any): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/consumables/', {headers: this.headers, params});
    } else {
      return this.http.get(this.apiURL + '/admin/consumables/' + id, {
        headers: this.headers,
        params,
      });
    }
  }

  public deleteConsumable(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/consumables/' + id, {
      headers: this.headers,
    });
  }

  public createConsumable(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/consumables', data, {
      headers: this.headers,
    });
  }

  public deleteConsumableTicketAssociation(
    consumable_ticket_id: string
  ): Observable<any> {
    return this.http.delete(
      this.apiURL +
      '/admin/consumable-ticket-association/' +
      consumable_ticket_id,
      {headers: this.headers}
    );
  }

  public createConsumableTicket(data: any): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/consumable-ticket-association',
      data,
      {headers: this.headers}
    );
  }

  public createWorklogConsumable(data: any): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/consumable-ticket-association',
      data,
      {headers: this.headers}
    );
  }

  public getTicketConsumables(ticketId: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/ticket/' + ticketId + '/consumables',
      {headers: this.headers}
    );
  }

  public getWorklogConsumables(worklogId: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/worklog/' + worklogId + '/consumables',
      {headers: this.headers}
    );
  }

  public geConsumablesStats(httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/consumables/stats', {
      headers: this.headers,
      params: httpParams,
    });
  }

  public getCheckListTemplatesByObjectId(object_id: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/check-list-templates/object/' + object_id,
      {headers: this.headers}
    );
  }

  public getCheckListsByObjectId(object_id: string): Observable<any> {
    return this.http.get(
      this.apiURL + '/admin/check-list/object/' + object_id,
      {headers: this.headers}
    );
  }

  public setCheckListTemplate(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/check-list-templates', data, {
      headers: this.headers,
    });
  }

  public setCheckList(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/check-list', data, {headers: this.headers});
  }

  public deleteCheckListTemplate(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/check-list-templates/' + id, {
      headers: this.headers,
    });
  }

  public deleteCheckList(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/check-list/' + id, {
      headers: this.headers,
    });
  }

  public deletePropertyManagementConnection(objectid: string): Observable<any> {
    return this.http.delete(
      this.apiURL + '/admin/property-management-connection/' + objectid,
      {headers: this.headers}
    );
  }

  public getPropertyManagement(id?: string): Observable<any> {
    if (!id) {

      return this.http.get(this.apiURL + '/admin/property-management', {
        headers: this.headers,
      });
    } else {
      return this.http.get(this.apiURL + '/admin/property-management/' + id, {
        headers: this.headers,
      });
    }
  }

  public searchPropertyManagement(httpParams: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/property-management/search', {
      headers: this.headers,
      params: httpParams,
    });
  }

  public createPropertyManagementRequest(
    data: PropertyManagementRequest
  ): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/property-management-request',
      data,
      {headers: this.headers}
    );
  }

  public cancelPropertyManagementRequest(objectid: string): Observable<any> {
    return this.http.delete(
      this.apiURL + '/admin/property-management-request/' + objectid,
      {headers: this.headers}
    );
  }

  public getTransaction(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/admin/transaction', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/admin/transaction/' + id, {
        headers: this.headers,
      });
    }
  }


  public getRenewInterval(id?: string): Observable<any> {
    if (!id) {
      return this.http.get(this.apiURL + '/renewintervals', {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/renewintervals/' + id, {headers: this.headers});
    }
  }

  public getTicketsChartData(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/chart/tickets', {headers: this.headers});
  }

  public getUserResidualValue(userId: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/order-process/user-residual-value/' + userId, {headers: this.headers});
  }

  public upgradeUserRenewInterval(upgradeData: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/order-process/user-upgrade-renewinterval/', upgradeData, {headers: this.headers});
  }

  public getObjectsChartData(id?: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/chart/objects/' + id, {headers: this.headers});
  }

  public getRoomsByObject(objectId: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + objectId + '/rooms', {headers: this.headers});
  }

  public setRoomsByObject(data: Room): Observable<any> {
    return this.http.post(this.apiURL + '/admin/room', data, {headers: this.headers});
  }


  public deletetRoomsByObject(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/room/' + id, {headers: this.headers});
  }

  public downloadWorklogPDF(worklog: any): Observable<any> {
    return this.http.post(this.apiURL + '/worklog/pdf/', worklog, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  public downloadObjectFlyerPDF(object: any): Observable<any> {
    return this.http.post(this.apiURL + '/objectFlyer/pdf/', object, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  public getGlobalNews(): Observable<any> {
    return this.http.get(this.apiURL + '/global-news', {headers: this.headers});
  }

  public placeOrder(data?: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/order-process/', data, {
      headers: this.headers,
    });
  }

  public setCustomerModule(modulename: string): Observable<any> {
    return this.http.post(
      this.apiURL + '/admin/order-process/' + modulename,
      {},
      {headers: this.headers}
    );
  }

  public getPublicHolidays(bundeslandId?: string): Observable<any> {
    if (bundeslandId) {
      return this.http.get(this.apiURL + '/holidays/' + bundeslandId, {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/holidays', {headers: this.headers});
    }
  }

  public setSharedTicket(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/shared-ticket/', data, {headers: this.headers});
  }

  public setSharedWorkLog(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/shared-worklog/', data, {headers: this.headers});
  }

  public getTicketChangelog(id?: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/ticket-changelog/' + id, {headers: this.headers});
  }

  public getTimeStampsDays(id: string, httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/timestamp/user/' + id, {headers: this.headers, params: httpParams});
  }

  public getTimetrackingTypes(): Observable<any> {
    return this.http.get(this.apiURL + '/timetracking-types', {headers: this.headers});
  }

  public getTimetrackingSaldo(id: string, httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/timestamp-saldo/user/' + id, {headers: this.headers, params: httpParams});
  }

  public getUserTimetrackingMonths(id: string, httpParams?: HttpParams): Observable<any> {
    return this.http.get(this.apiURL + '/admin/timestamp-months/user/' + id, {headers: this.headers, params: httpParams});
  }

  public setTimestamp(data: TimeTrackingTimeStamps): Observable<any> {
    return this.http.post(this.apiURL + '/admin/timestamp/', data, {headers: this.headers});
  }

  public setEmployeeTimestamp(data: TimeTrackingTimeStamps): Observable<any> {
    return this.http.post(this.apiURL + '/employee/timestamp/', data, {headers: this.headers});
  }

  public getTimeStamps(): Observable<any> {
    return this.http.get(this.apiURL + '/employee/timestamp', {headers: this.headers});
  }

  public setTimetrackingMonth(data: TimeTrackingMonth): Observable<any> {
    return this.http.put(this.apiURL + '/admin/timestamp-months/' + data.id, data, {headers: this.headers});
  }

  public lockTimetrackingMonth(id: string): Observable<any> {
    return this.http.post(this.apiURL + '/admin/timestamp-months/' + id + '/lock', {}, {headers: this.headers});
  }

  public setTimetrackingDayManual(data: TimeTrackingDays): Observable<any> {
    return this.http.post(this.apiURL + '/admin/timestamp-day', data, {headers: this.headers});
  }

  public deleteTimestamp(id: string | any): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/timestamp/' + id, {headers: this.headers});
  }

  public getFiles(where: any): Observable<any> {
    return this.http.get(this.apiURL + '/file/' , {headers: this.headers, params: where});
  }

  public updateFile(data: File): Observable<any> {
    return this.http.put(this.apiURL + '/file/' + data.id, data, {headers: this.headers});
  }

  public updateFileBulk(data: File[]): Observable<any> {
    return this.http.put(this.apiURL + '/file/bulk', data, {headers: this.headers});
  }

  public getServiceCatalog(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/service-catalog/' + id, {headers: this.headers});
  }

  public deleteServiceCatalogItem(id: string | any): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/service-catalog-items/' + id, {headers: this.headers});
  }

  public getAllServiceCatalog(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/service-catalog/', {headers: this.headers});
  }

  public setServiceCatalog(data: ServiceCatalog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/service-catalog/', data, {headers: this.headers});
  }

  public deleteServiceCatalog(id: string | any): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/service-catalog/' + id, {headers: this.headers});
  }

  public getCatalogbyObject(objectId: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/object/' + objectId + '/serviceCatalogs', {headers: this.headers});
  }

  public setObjectsOfCatalogs(data: any): Observable<any> {
    return this.http.post(this.apiURL + '/admin/service-catalog/obj', data, {
      headers: this.headers,
    });
  }

  public deleteObjectCatalogAssociation(
    object_id: string,
    catalog_id: string
  ): Observable<any> {
    return this.http.delete(
      this.apiURL +
      '/admin/object-catalog-association/' +
      object_id +
      '/' +
      catalog_id,
      {headers: this.headers}
    );
  }

  public setWorklogCompletedServices(data: WorkLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/completedServices', data, {headers: this.headers});
  }

  public deleteTaskLink(data: WorkLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/deleteTaskLink', data, {headers: this.headers});
  }

  public deleteTicketLink(data: WorkLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/worklog/deleteTicketLink', data, {headers: this.headers});
  }

  public writeActivityLog(data?: ActivityLog): Observable<any> {
    return this.http.post(this.apiURL + '/admin/activitylog/writeActvitiyLog', data, {headers: this.headers});
  }

  public setUsersObjectAssociation(data: Users_Objects[] | Users_Objects): Observable<any> {
    return this.http.post(this.apiURL + '/admin/user/users-objects-association/', data, {headers: this.headers});
  }

  public deleteContactAddress(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contactAddress/' + id, {headers: this.headers});
  }

  public deleteContactPhone(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contactPhone/' + id, {headers: this.headers});
  }

  public deleteContactEmail(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contactEmail/' + id, {headers: this.headers});
  }

  public deleteContactPersonEmail(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contact-person-Email/' + id, {headers: this.headers});
  }

  public deleteContactPersonPhone(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contact-person-Phone/' + id, {headers: this.headers});
  }

  public deleteContactPerson(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/contact/contactPerson/' + id, {headers: this.headers});
  }

  public deleteAvatar(userId?: string): Observable<any> {
    if (userId) {
      return this.http.delete(this.apiURL + '/admin/avatar/' + userId, {headers: this.headers});
    } else {
      return this.http.delete(this.apiURL + '/avatar/', {headers: this.headers});
    }
  }

  public getCounterTypes(): Observable<any> {
    return this.http.get(this.apiURL + '/counterTypes/', {headers: this.headers});
  }

  public getCounterStatusbyCounter(counterId: string): Observable<any> {
    return this.http.get(this.apiURL + '/counterStatus/counter/' + counterId, {headers: this.headers});
  }

  public getCounters(id?: string): Observable<any> {
    if (id) {
      return this.http.get(this.apiURL + '/counter/object/' + id, {headers: this.headers});
    } else {
      return this.http.get(this.apiURL + '/counters/', {headers: this.headers});
    }
  }

  public setCounter(data: Counter): Observable<any> {
    return this.http.post(this.apiURL + '/counter/', data, {headers: this.headers});
  }

  public setCounterStatus(data: CounterStatus[] | CounterStatus): Observable<any> {
    return this.http.post(this.apiURL + '/counterStatus/', data, {headers: this.headers});
  }

  public deleteCounter(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/deleteCounter/' + id, {
      headers: this.headers,
    });
  }

  public deleteCounterStatus(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/deleteCounterStatus/' + id, {
      headers: this.headers,
    });
  }

  public getAbsentTypes(): Observable<any> {
    return this.http.get(this.apiURL + '/admin/absentTypes/', {headers: this.headers});
  }

  public getAbsentsByUser(userId: string): Observable<any> {
    return this.http.get(this.apiURL + '/admin/absent/user/' + userId, {headers: this.headers});
  }

  public getAllAbsentsByUsers(filter: any): Observable<any> {
    let headers = this.headers;
    if (filter) {
      headers = this.headers
        .set('filter', JSON.stringify(filter));
    }
    return this.http.get(this.apiURL + '/admin/AllAbsentbyUsers/', {headers});
  }

  public setAbsent(data: Absent): Observable<any> {
    return this.http.post(this.apiURL + '/admin/absent/', data, {headers: this.headers});
  }

  public setAbsentApproved(data: Absent): Observable<any> {
    return this.http.post(this.apiURL + '/admin/absent/approved/', data, {headers: this.headers});
  }

  public deleteAbsentByUser(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/admin/absent/user/' + id, {headers: this.headers});
  }

  public getSessionsByUser(userId: string): Observable<any> {
    return this.http.get(this.apiURL + '/session/user/' + userId, {headers: this.headers});
  }

  public deleteSession(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/session/' + id, {headers: this.headers});
  }

  public getBundesleander(): Observable<any> {
    return this.http.get(this.apiURL + '/bundesleander/', {headers: this.headers});
  }

  public getWasteplan(filter?: any): Observable<any> {
    return this.http.get(this.apiURL + '/wasteplan', {headers: this.headers, params: filter});
  }

  public getWasteplanByObject(id: string): Observable<any> {
    return this.http.get(this.apiURL + '/wasteplan/object/' + id, {headers: this.headers});
  }

  public setWasteplan(data: Wasteplan): Observable<any> {
    return this.http.post(this.apiURL + '/wasteplan/', data, {headers: this.headers});
  }

  public setBulkWasteplan(data: Wasteplan | any): Observable<any> {
    return this.http.post(this.apiURL + '/wasteplan/bulk', data, {headers: this.headers});
  }

  public deleteWasteplan(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/wasteplan/' + id, {headers: this.headers});
  }

  public deleteWasteplansByObjectId(id: string): Observable<any> {
    return this.http.delete(this.apiURL + '/wasteplan/object/' + id, {headers: this.headers});
  }

  public lexofficeRvokeConnection(): Observable<any> {
    return this.http.post(this.apiURL + '/lexoffice/revoke', {},{headers: this.headers});
  }

  public lexofficeCreateInvoice(invoice: InvoiceCreate): Observable<any> {
    return this.http.post(this.apiURL + '/lexoffice/invoice', invoice,{headers: this.headers});
  }

  public lexofficeHandleAuthcode(params: any): Observable<any> {
    return this.http.get(this.apiURL + '/lexoffice/handle-authcode', {headers: this.headers, params: params});
  }

  public getLexofficeContacts(filter?: OptionalFilters & Partial<PagingParameters>): Observable<any> {
    return this.http.get(this.apiURL + '/lexoffice/contact', {headers: this.headers, params: filter});
  }

  public webhookUpdateLexofficeContact(lexofficeId: string): Observable<any> {
    return this.http.post(this.apiURL + '/webhook-lexoffice/contact', {
      eventType: "contact.changed",
      resourceId: lexofficeId
    }, {headers: this.headers});
  }
}
