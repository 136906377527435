import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {NotificationService} from './notification.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Router} from '@angular/router';
import {FileBrowserComponent} from '../components/file-browser/file-browser.component';
import {WorkLog} from '../../../../../database-models';
import {NzModalService} from 'ng-zorro-antd/modal';
import {
  LexofficeWorklogToInvoiceComponent
} from '../components/lexoffice/lexoffice-worklog-to-invoice/lexoffice-worklog-to-invoice.component';

@Injectable({
  providedIn: 'root'
})
export class LexofficeService {

  public authCodeFlowConfig: AuthConfig = {
    issuer: environment.lexofficePartnerApi.authorizationurl,
    tokenEndpoint: environment.lexofficePartnerApi.authorizationurl,
    loginUrl: environment.lexofficePartnerApi.authorizationurl,
    redirectUri: 'https://redirectmeto.com/http://localhost:4200/company', // environment.webURL + '/company',
    clientId: '079ad2cb-4d9a-4fcd-bd43-b8c4c6814dbb',
    responseType: 'code',
    oidc: false,
    scope: 'profile.read contacts.read contacts.write invoices.write invoices.read credit-notes.write credit-notes.read files.read payments.read',
    showDebugInformation: true,
    customQueryParams: {
      connection_name: 'Hausmeisterapp.com',
      grant_type: 'authorization_code'
    }
  };

  constructor(
    private oauthService: OAuthService,
    private api: ApiService,
    private router: Router,
    private modalService: NzModalService,
    private notification: NzNotificationService
  ) {
  }

  public async startOauth(): Promise<void> {

    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.events.subscribe(e => console.log(e));
    this.oauthService.initCodeFlow();

  }

  public async revokeConnection(): Promise<void> {

    this.api.lexofficeRvokeConnection().subscribe(res => {
      this.notification.success('LexOffice wurde erfolgreich getrennt', 'Die Verbindung zwischen LexOffice und der Hausmeisterapp.com wurde erfolgreich getrennt.');
      setTimeout(() => {
        location.reload();
      }, 2000)
    }, error => {
      this.notification.error('Fehler', 'Es war nicht möglich die Verbindung zu trennen. Bitte versuchen Sie es später erneut oder trennen Sie die Verbindung in LexOffice.')
    });

  }

  public worklogToInvoice(worklog: WorkLog): void {

      const modal = this.modalService.create({
        nzContent: LexofficeWorklogToInvoiceComponent,
        nzComponentParams: {
          worklog: worklog
        },
        nzFooter: null,
        nzWidth: '90%',
      });

      modal.afterClose.subscribe((data: any) => {
        if (data) {
          console.log(data);
        }
      });
    }

}
