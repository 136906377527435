export const environment = {
  production: false,
  webURL: 'https://dev.hausmeisterapp.com',
  apiURL: 'https://dev-api.hausmeisterapp.com',
  lexApiUrl: '/lexofficeapi',
  googleApiKey: 'AIzaSyAByxN6OqklAULsFBuDxu4r1rXwOAv0BKA',
  lexofficePartnerApi: {
    authorizationurl: 'https://lexoffice-sandbox.grld.eu/oauth2/authorize',
    resourceurl: 'https://lexoffice-sandbox.grld.eu',
    uiUrl: 'https://lexoffice-sandbox.grld.eu'
  }
};
