import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {momentTimezone} from '@mobiscroll/angular';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TimetrackingService} from '../../../services/timetracking.service';
import {ExportService} from '../../../services/export.service';
import {DatePipe} from '@angular/common';
import {TimeTrackingDays, TimeTrackingMonth} from '../../../../../../../database-models';

@Component({
  selector: 'app-timestamp-list-months',
  templateUrl: './timestamp-list-months.component.html',
  styleUrls: ['./timestamp-list-months.component.scss']
})
export class TimestampListMonthsComponent implements OnInit {


  get userId(): string {
    return this._userId;
  }

  @Input()
  set userId(value: string) {
    this._userId = value;
    this.getMonths();
  }


  private _userId: string;

  @Output()
  public updated: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Output()
  public selectMonth: EventEmitter<string> = new EventEmitter<string>();


  public timeTrackingMonths: TimeTrackingMonth[];

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public authenticationService: AuthenticationService,
    private message: NzMessageService,
    private modalService: NzModalService,
    public timetracking: TimetrackingService,
    public exportService: ExportService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getMonths();
  }

  public async getMonths(): Promise<void> {

    this.api.getUserTimetrackingMonths(this.userId).subscribe((months: TimeTrackingMonth[]) => {
      this.timeTrackingMonths = months;
    }, error => {
      console.error(error);
      this.message.error('Monate konnten nicht geladen werden');
    });
  }

  public lock(month: TimeTrackingMonth): void {
    this.api.lockTimetrackingMonth(month.id).subscribe(res => {
      this.message.success('Der Monat wurde abgeschlossen');
      this.getMonths();
      this.updated.emit(true);
    }, error => {
      this.message.error('Es ist ein Fehler aufgetreten!');
    });
  }

  public setMonth(month: TimeTrackingMonth): void {
    setTimeout(() => {
      this.api.setTimetrackingMonth(month).subscribe(res => {
        this.message.success('Der Monat wurde gespeichert');
        this.getMonths();
        this.updated.emit(true);
      }, error => {
        this.message.error('Es ist ein Fehler aufgetreten!');
      })
    }, 100);

  }

  public calcPercent(day: TimeTrackingDays | TimeTrackingMonth): number {
    let percent = (100 / day.needed) * day.total;
    return (percent > 100 ? 100 : percent) ;
  }

  protected readonly moment = moment;
  protected readonly momentPlugin = momentTimezone;
}
