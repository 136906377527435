import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalnewsService} from '../../services/globalnews.service';

@Component({
  selector: 'app-support-drawer',
  templateUrl: './support-drawer.component.html',
  styleUrls: ['./support-drawer.component.scss']
})
export class SupportDrawerComponent implements OnInit {

  public visible = false;
  public knowledge = [];
  public knowledgeBaseUrl = 'https://service.netbrick.de';

  public open(): void {
    this.visible = true;
    this.knowledge = [];
    console.log(this.router.url);
    this.searchKnowledge(this.router.url.split('/')[1])
  }

  public close(): void {
    this.visible = false;
  }

  constructor(
    public router: Router,
    public http: HttpClient,
    public authenticationService: AuthenticationService,
    public globalNewsService: GlobalnewsService
  ) { }

  ngOnInit(): void {

  }

  public searchKnowledge(query: string): void {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    this.http.post('https://service.netbrick.de/api/v1/knowledge_bases/search', {
      "knowledge_base_id": "1",
      "locale": "de-de",
      "query": query,
      "flavor": "public"
    }, {headers}).subscribe((results: any) => {
      console.log(results);
      this.knowledge = results?.details;
    });
  }

}
