import {Component} from '@angular/core';
import {ROUTES} from './side-nav-routes.config';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {AuthenticationService} from '../../services/authentication.service';
import {LicenseService} from '../../services/license.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;

  constructor(
    private themeService: ThemeConstantService,
    private authenticationService: AuthenticationService,
    public licenseService: LicenseService
  ) {
  }

  ngOnInit(): void {
    if (this.authenticationService.currentUserValue.isAdmin) {
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    } else {
      this.menuItems = ROUTES.filter(menuItem => {
        return !menuItem.adminOnly;
      });
    }
    if (this.authenticationService.currentUserValue.isManager) {
      this.menuItems = this.menuItems.filter(menuItem => menuItem);
    } else {
      this.menuItems = this.menuItems.filter(menuItem => {
        return !menuItem.managerOnly;
      });
    }
    if (!this.authenticationService.currentUserValue.company?.user_object_permissions) {
      for (let item of this.menuItems) {
        item.submenu = item.submenu.filter((menuItem) => {
          return !menuItem.permissionSystem;
        });

      }
    }


    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
  }
}
