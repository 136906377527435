import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {AppUserTypes, Object, User} from "../../../../../../database-models";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {ResidentDetailsComponent} from "./resident-details/resident-details.component";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-object-residents',
  templateUrl: './object-residents.component.html',
  styleUrls: ['./object-residents.component.scss']
})
export class ObjectResidentsComponent implements OnInit {

  public residents: User[];
  public residentsOriginal: User[];
  public nameSearch: string;
  public isLoading = false;
  public isExpand: boolean = false;
  public includeBlocked: boolean = false;

  public sortOrders = {
    name: null,
    objekt: null
  };

  constructor(
      public api: ApiService,
      public message: NzMessageService,
      private modalService: NzModalService,
  ) {
  }

  ngOnInit(): void {
    this.getObjectResidents();

    const storedOrderFn = localStorage.getItem('tableheaderResidents');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
  }

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderResidents', JSON.stringify(this.sortOrders));
  }

  public orderFn = {
    name: (a, b) => a.name?.localeCompare(b.name)
  }

  public getObjectResidents(): void {
    this.isLoading = true;
    let params: any = {};
    if (!this.includeBlocked) {
      params.blocked = false;
    }
    this.api.getAppusers(null, params).subscribe((residents: User[]) => {
      this.residents = residents;
      this.residentsOriginal = residents;
      this.isLoading = false;
    }, error => {
      console.error(error)
    });
  }

  public residentDetail(appuser: User): void {
    const editModal = this.modalService.create({
      nzContent: ResidentDetailsComponent,
      nzWidth: 700,
      nzComponentParams: {
        appuser: appuser
      },
      nzFooter: null
    });
    editModal.afterClose.subscribe((data: any) => {
      this.getObjectResidents();
    });
  }

  public getTagColor(appuserType: AppUserTypes): string {
    switch (appuserType) {
      case 'mieter':
        return 'red';
      case 'eigentuemer':
        return 'blue';
      default:
        return 'gold';
    }
  }

  public filter() {
    if (this.nameSearch) {
      let searchString = this.nameSearch.toLowerCase();
      this.residents = this.residentsOriginal.filter((resident: User) => {
        return resident.name?.toLowerCase().includes(searchString)
      })
    } else {
      this.residents = this.residentsOriginal;
    }
  }

  public capitalize(s): void {
    return s[0].toUpperCase() + s.slice(1);
  }
}
