<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  nzTitle="Hilfe & Support"
  (nzOnClose)="close()"
>
  <ng-container *nzDrawerContent>

    <nz-list nzItemLayout="horizontal">
      <nz-list-item class="cursor-pointer" *ngFor="let item of globalNewsService.news" (click)="globalNewsService.createNotification(item)">
        <nz-list-item-meta
          [nzAvatar]="notificationIcon" [nzDescription]="globalNewsService.getTypeTitle(item.type)"
        >
          <ng-template #notificationIcon>
            <nz-avatar nzIcon="notification" [style.background-color]="globalNewsService.getColor(item.type)"></nz-avatar>
          </ng-template>
          <nz-list-item-meta-title>
            {{ item.title }}
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </nz-list-item>
    </nz-list>
    <nz-divider *ngIf="globalNewsService.news?.length > 0"></nz-divider>
    <div>
      <h5>Nützliche Links</h5>
      <ul class="ant-list">
        <li><i nz-icon nzType="bulb" nzTheme="outline"></i> <a href="https://service.netbrick.de/help/de-de"
                                                               target="_blank">Wissensdatenbank / FAQ</a></li>
        <li><i nz-icon nzType="comment" nzTheme="outline"></i> <a href="https://service.netbrick.de" target="_blank">Helpdesk</a>
        </li>
      </ul>
    </div>
    <div class="m-t-30">
      <h5>Kontakt</h5>
      <ul class="ant-list">
        <li><i nz-icon nzType="mail" nzTheme="outline"></i> <a href="mailto:service@hausmeisterapp.com"
                                                               target="_blank">service@hausmeisterapp.com</a></li>
        <li><i nz-icon nzType="phone" nzTheme="outline"></i> <a href="tel:00498131316464" target="_blank">+49 (0)8131
          316464</a>
        </li>
      </ul>
    </div>
    <div *ngIf="knowledge?.length > 0" class="m-t-30">
      <h5>Hilfeartikel</h5>
      <ul class="m-l-15">
        <li *ngFor="let item of knowledge">
          <a [href]="knowledgeBaseUrl + item.url" target="_blank"><span [innerHTML]="item.title"></span></a>
        </li>
      </ul>
    </div>

    <nz-divider></nz-divider>

        <small>
        Kunden-ID: {{authenticationService.currentUserValue?.company?.code}}<br>
        User-ID: {{authenticationService.currentUserValue?.id}}
        </small>



  </ng-container>
</nz-drawer>
