import {Component, Input, OnInit, Optional} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  ContactFull,
  InvoiceCreate,
  LineItem,
  OptionalFilters,
  PagingParameters
} from '../../../../../../../Shared/lexoffice-client-js-main/src';
import {WorkLog} from '../../../../../../../database-models';
import * as moment from 'moment';
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'app-lexoffice-worklog-to-invoice',
  templateUrl: './lexoffice-worklog-to-invoice.component.html',
  styleUrls: ['./lexoffice-worklog-to-invoice.component.css']
})
export class LexofficeWorklogToInvoiceComponent implements OnInit {

  @Input()
  public worklog: WorkLog;

  public lxInvoice: InvoiceCreate;

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.worklogToInvoice();
  }

  public worklogToInvoice(): void {
    this.lxInvoice = {
      archived: false,
      voucherDate: moment().toISOString(),
      address: {
        name: 'TEST',
        countryCode: 'DE'
      }, //todo
      lineItems: [],
      totalPrice: {
        currency: 'EUR'
      },
      taxConditions: {
        taxType: 'net'
      },
      shippingConditions: {
        shippingDate: this.worklog.start,
        shippingEndDate: this.worklog.end,
        shippingType: 'service'
      }
    };









  }

  public addWorkTime(): void {
    let worktime = {
      type: 'custom',
      name: this.worklog.description || 'Arbeitszeit',
      quantity: moment(this.worklog.end).diff(this.worklog.start, 'hours', true),
      unitName: 'Stunde',
      unitPrice: {
        currency: 'EUR',
        netAmount: 10,
        taxRatePercentage: 19
      }
    } as LineItem;
    if (this.worklog.completedServices?.length > 0) {
      worktime.description = this.worklog.completedServices.map(service => '- ' + service.name).join('\n');
    }
    this.lxInvoice.lineItems.push(worktime);
  }

  public addConsumables(): void {
    for (let consumable of this.worklog.consumed) {
      this.lxInvoice.lineItems.push({
        type: 'custom',
        name: consumable.name,
        description: consumable.description,
        quantity: consumable.Consumable_Ticket?.number,
        unitName: consumable.unit,
        unitPrice: {
          currency: 'EUR',
          netAmount: 0,
          taxRatePercentage: 19
        }
      });
    }
  }


  public addGPS(): void {
    for (let geo of this.worklog.geolocations) {
      this.lxInvoice.lineItems.push({
        type: 'text',
        name: 'GPS-Standortnachweis',
        description: 'Zeitpunkt: ' + moment(geo.createdAt).format('DD.MM.YYYY HH:mm') + '\nLatitude: ' + geo.latitude + '\n' + 'Longitude: ' + geo.longitude,
      });
    }
  }

  public deleteLineItem(index: number): void {
    this.lxInvoice.lineItems.splice(index, 1);
  }

  public cloneLineItem(index: number): void {
    this.lxInvoice.lineItems.splice(index, 0, this.lxInvoice.lineItems[index]);
  }

  public sendToLexoffice(): void {
    this.api.lexofficeCreateInvoice(this.lxInvoice).subscribe(result => {
      this.notification.success('Erfolgreich', 'Die Rechnung wurde erfolgreich erstellt');
      window.open(environment.lexofficePartnerApi.uiUrl + '/voucher/' + result.id, '_blank');
    }, error => {
      this.notification.error('Fehler', 'Die Rechnung konnte nicht erstellt werden');
    });
  }


}
