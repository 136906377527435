import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Absent, Company, TimeTrackingDays, TimeTrackingMonth, TimeTrackingTimeStamps, User} from '../../../../../../../database-models';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import * as moment from 'moment';
import {momentTimezone} from '@mobiscroll/angular';
import * as uuid from 'uuid';
import {HttpParams} from '@angular/common/http';
import {TimetrackingService} from '../../../services/timetracking.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {DatePipe} from '@angular/common';
import {ExportService} from '../../../services/export.service';
import {AbsentComponent} from '../../absent/absent/absent.component';
import {environment} from '../../../../../environments/environment';
import {now} from 'moment';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
  selector: 'app-timestamp-list',
  templateUrl: './timestamp-list.component.html',
  styleUrls: ['./timestamp-list.component.scss']
})
export class TimestampListComponent implements OnInit {

  public momentPlugin = momentTimezone;
  public timeTrackingMonths: TimeTrackingMonth[];
  public timeTrackingMonth: TimeTrackingMonth;
  public day_Timestamps: TimeTrackingDays[];
  public timeTrackingTotalSaldo: number;
  public user: User;
  public company: Company;
  public isLoading: boolean = false;
  public hide: boolean = false;
  public title = 'Zeiterfassung';
  public userId: string;

  public activeView: 'details' | 'months' = 'details';

  public employeeList: User[];
  public local: {
    'lang': {
      'locale': 'de_DE'
    }
  };
  public userAbsents: Absent[];
  public userAbsentsNotApproved: Absent[];
  public backendURL = environment.apiURL;

  public editCache: {
    [key: string]: { edit: boolean; data: TimeTrackingTimeStamps; original?: TimeTrackingTimeStamps }
  } = {};

  public test;

  public filter = {
    date: moment().toISOString()
  };

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public authenticationService: AuthenticationService,
    private message: NzMessageService,
    private modalService: NzModalService,
    public timetracking: TimetrackingService,
    public exportService: ExportService,
    private datePipe: DatePipe
  ) {
    this.route.params.subscribe(params => {
      this.userId = params['id'] || null;
    });
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(user => {
      this.company = user.company;
    });

    const storageFilter = localStorage.getItem('timetracking_filter');
    if (storageFilter) {
      this.filter = JSON.parse(storageFilter);
    }

    this.getUsers();
  }

  public userChanged(): void {
    this.router.navigate(['/timetracking/' + this.user.id]);
    this.getTimestamps(moment().toISOString());
  }

  public getUsers(): void {
    this.api.getUsers().subscribe((user: User[]) => {
      this.employeeList = user;
      this.user = this.employeeList.find(empl => empl.id === this.userId);
      this.getTimestamps();
    }, onerror => {
      console.log(onerror);
      this.message.error('Mitarbeiter konnten nicht geladen werden');
    });
  }


  public async getTimestamps(date?: string): Promise<void> {
    if (!this.user) {
      return null;
    }

    let httpParams = new HttpParams();

    if (date) {
      this.filter.date = date;
      this.activeView = 'details';
    }
    if (this.filter.date) {
      httpParams = httpParams.append('month', moment(this.filter.date).format('MM'));
      httpParams = httpParams.append('year', moment(this.filter.date).year());
    }

    localStorage.setItem('timestamp_filter', JSON.stringify(this.filter));

    this.isLoading = true;
    this.api.getTimeStampsDays(this.user.id, httpParams).subscribe((month: TimeTrackingMonth) => {
      this.timeTrackingMonth = month;
      this.day_Timestamps = month?.timetracking_days;
      // @ts-ignore
      this.updateEditCache();
      this.getMonths();
      this.isLoading = false;
    }, error => {
      console.error(error);
      this.message.error('Zeiten konnten nicht geladen werden');
      this.isLoading = false;
    });

    this.getAbsentsByUser();

  }

  public async getMonths(): Promise<void> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('limit', 6);
    this.api.getUserTimetrackingMonths(this.user.id, httpParams).subscribe((months: TimeTrackingMonth[]) => {
      this.timeTrackingMonths = months.reverse();
      this.getTimetrackingSaldo();
    }, error => {
      console.error(error);
      this.message.error('Monate konnten nicht geladen werden');
    });
  }

  public async getTimetrackingSaldo(): Promise<void> {
    this.api.getTimetrackingSaldo(this.user.id).subscribe((totalSaldo: { saldo: number }) => {
      this.timeTrackingTotalSaldo = totalSaldo.saldo;
    }, error => {
      console.error(error);
      this.message.error('totalSaldo konnte nicht geladen werden');
    });
  }

  expandSet = new Set<string>();

  onExpandChange(id: string, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  public editTimestamp(stamp: TimeTrackingTimeStamps): void {
    this.hide = false;
    this.editCache[stamp.id].edit = true;
    this.editCache[stamp.id].data = JSON.parse(JSON.stringify(stamp));
    this.editCache[stamp.id].original = JSON.parse(JSON.stringify(stamp));
  }

  public saveEdit(id: string, dayStampIndex: number, stampIndex: number): void {
    this.day_Timestamps[dayStampIndex].timetracking_timestamps[stampIndex] = this.editCache[id].data;
    const data = this.day_Timestamps[dayStampIndex].timetracking_timestamps[stampIndex];

    const day = moment(this.day_Timestamps[dayStampIndex].date);
    const mourMinute = moment(data.timestamp);
    data.timestamp = day.set({
      hour: mourMinute.get('hour'),
      minute: mourMinute.get('minute'),
      second: 0,
      millisecond: 0
    }).toISOString();

    this.day_Timestamps[dayStampIndex] = this.timetracking.timestampValidator(this.day_Timestamps[dayStampIndex]);
    if (!data.user_id) {
      data.user_id = this.userId;
    }
    data.manipulated = true
    this.api.setTimestamp(data).subscribe(value => {
      this.message.success('Zeitstempel wurde aktualisiert');
      this.editCache[id].edit = false;
      this.getTimestamps();
    }, error => {
      console.log('error');
    });
  }

  public deleteTimestamp(id: string): void {
    this.hide = false;
    this.modalService.warning({
      nzTitle: '<i>Zeiteintrag löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie diesen Zeiteintrag löschen möchten?',
      nzOnOk: () => this.deleteTs(id),
      nzCancelText: 'Abbrechen',
    });
  }

  private deleteTs(id: string): void {
    this.hide = false;
    this.api.deleteTimestamp(id).subscribe((timestamp: TimeTrackingTimeStamps) => {
      this.message.success('Zeiteintrag erfolgreich gelöscht.');
      this.getTimestamps();
    });

  }

  public cancelEdit(id: string, dayStampIndex: number, stampIndex: number): void {
    this.day_Timestamps[dayStampIndex].timetracking_timestamps[stampIndex] = this.editCache[id].original;
    this.editCache[id].edit = false;
    this.getTimestamps();
  }

  public newTimeStamp(day: TimeTrackingDays, $e?): void {
    this.hide = true;
    const newId = uuid.v4();
    const stamp = {
      id: newId,
      timestamp: this.timetracking.getLastStampOfDay(day)?.timestamp || moment(day.date).add(6, 'hours').toISOString(),
      user_id: day.user_id
    } as TimeTrackingTimeStamps;
    day.timetracking_timestamps.push(stamp);
    this.editCache[newId] = {
      edit: true,
      data: stamp
    };
    setTimeout(() => {
      if ($e) {
        const newRow = document.getElementById(newId);
        newRow.scrollIntoView({behavior: 'smooth'});
      }
    }, 100);
  }

  public isDayInEditMode(day: TimeTrackingDays): boolean {
    let mode = false;
    for (let stamp of day.timetracking_timestamps) {
      if (this.editCache[stamp?.id]?.edit) {
        mode = true;
      }
    }
    return mode;
  }

  public updateEditCache(): void {
    this.day_Timestamps?.forEach(day => {
      if (day.timetracking_timestamps && day.timetracking_timestamps.length > 0) {
        day.timetracking_timestamps.forEach(ts => {
          this.editCache[ts.id] = {
            edit: false,
            data: {...ts},
            original: {...ts},
          };
        });
        day = this.timetracking.timestampValidator(day);
      }

    });
  }

  public export(type: 'excel' | 'csv'): void {
    const exportJSON: any[] = [];
    const selectedTimestamps = this.day_Timestamps;

    let rows = [];

    for (const sT of selectedTimestamps) {
      const row = {
        Tag: this.datePipe.transform(sT.date, 'EEEE', 'local', 'de-DE'),
        Datum: this.datePipe.transform(sT.date, 'dd.MM.y'),
        Arbeitszeit: this.timetracking.convertTime(sT.worktime) ? this.timetracking.convertTime(sT.worktime) : ' ',
        Pausenzeit: this.timetracking.convertTime(sT.breaktime) ? this.timetracking.convertTime(sT.breaktime) : ' ',
        Abwesenheit: this.timetracking.convertTime(sT.absenttime) ? this.timetracking.convertTime(sT.absenttime) : ' ',
        Abwesentheitsart: sT.absents[0]?.halfDay ? (sT.absents[0]?.absentType?.name ? '0,5 ' + sT.absents[0]?.absentType?.name : ' ') : (sT.absents[0]?.absentType?.name ? sT.absents[0]?.absentType?.name : ' '),
        Feiertag: sT.holiday?.name ? sT.holiday?.name : ' ',
        Soll: this.timetracking.convertTime(sT.needed) ? this.timetracking.convertTime(sT.needed) : ' ',
        Summe: this.timetracking.convertTime(sT.total) ? this.timetracking.convertTime(sT.total) : ' ',
        Differenz: this.timetracking.convertTime(sT.saldo) ? this.timetracking.convertTime(sT.saldo) : ' ',
      };

      rows.push(row);
    }

    const rowSum = {
      Tag: 'Gesamt:',
      Datum: selectedTimestamps.length + ' Tage',
      Arbeitszeit: this.timetracking.convertTime(this.timeTrackingMonth.worktime),
      Pausenzeit: this.timetracking.convertTime(this.timeTrackingMonth.breaktime),
      Abwesenheit: this.timetracking.convertTime(this.timeTrackingMonth.absenttime),
      Feiertag: '',
      Soll: this.timetracking.convertTime(this.timeTrackingMonth.needed),
      Summe: this.timetracking.convertTime(this.timeTrackingMonth.total),
      Differenz: this.timetracking.convertTime(this.timeTrackingMonth.saldo),
    };

    exportJSON.push(...rows, {}, rowSum);
    const currentMonth = moment(this.filter.date).locale('de').format('MMMM yyyy')
    if (type === 'excel') {
      this.exportService.exportJsonToExcel(exportJSON, 'Zeiterfassung ' + this.user.name + ' ' + currentMonth);
    } else if (type === 'csv') {
      this.exportService.exportToCsv(exportJSON, 'Zeiterfassung ' + this.user.name + ' ' + currentMonth);
    }
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  protected readonly moment = moment;

  public openSupport(): void {

    // @ts-ignore
    let sub = 'Zeiterfassung Testen Kunden-ID: ' + this.company.code;
    window.location.assign('mailto:service@hausmeisterapp.com?Subject=' + encodeURIComponent(sub));
  }

  public absentEditor(): void {
    const modal = this.modalService.create({
      nzContent: AbsentComponent,
      nzComponentParams: {
        userId: this.userId
      },
      nzFooter: null,
      nzWidth: 1300,
    });

    modal.afterClose.subscribe((data: any) => {
      this.getTimestamps();
    });
  }

  public getAbsentsByUser(): void {
    this.api.getAbsentsByUser(this.user.id).subscribe((value: Absent[]) => {
      this.userAbsents = value;
      this.userAbsentsNotApproved = value.filter(abs => !abs.approved);
    });
  }

  public disabledDate = (current: Date): boolean => {
    const maxEndDate = moment().add(1, 'month').endOf('month');
    let maxStartDate: any;
    if (this.user?.timeTrackingBegin) {
      maxStartDate = moment(this.user.timeTrackingBegin);
    } else {
      maxStartDate = moment();
    }
    return moment(current).isAfter(maxEndDate, 'day') || moment(current).isBefore(maxStartDate);
  };

  public isMonthActive(month: TimeTrackingMonth): boolean {
    return month.month === this.timeTrackingMonth?.month && month.year === this.timeTrackingMonth?.year;
  }

  public isMonthFuture(month: TimeTrackingMonth): boolean {
    const selected = moment(month.year + (month.month.toString().padStart(2, '0')));
    return selected.isAfter(new Date(), 'month');
  }

  public calcPercent(day: TimeTrackingDays | TimeTrackingMonth): number {
    let percent = (100 / day.needed) * day.total;
    return (percent > 100 ? 100 : percent) ;
  }


  public printPDF(): void {

    let header = ['Tag', 'Arbeitszeit', 'Pausenzeit', 'Abwesenheit', '', 'Soll', 'Summe', ''];

    let exportJSON: any[] = [];

    for (const day of this.day_Timestamps) {
      const row = [
        {
          content: this.datePipe.transform(day.date, 'E, dd.MM.y', 'local', 'de-DE'),
          styles: {
            fontSize: 9
          },
          colSpan: 1
        },
        {
          content: day.worktime ? this.timetracking.convertTime(day.worktime) : '',
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.breaktime ? this.timetracking.convertTime(day.breaktime) : '',
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.absenttime ? this.timetracking.convertTime(day.absenttime) : '',
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.holiday?.name ? day.holiday?.name : this.timetracking.isWeekend(day) ? 'Wochenende' : (day.absenttime ? day.absents[0].absentType?.name : ''),
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.needed ? this.timetracking.convertTime(day.needed) : '',
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.total ? this.timetracking.convertTime(day.total) : '',
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: day.saldo ? (day.saldo > 0 ? '+' + this.timetracking.convertTime(day.saldo) : this.timetracking.convertTime(day.saldo)) : '',
          styles: {
            fontSize: 6
          },
          colSpan: 1
        }
      ];
      exportJSON.push(row);
    }

    const footer = [
      {
        content: 'Gesamt: ',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: this.timeTrackingMonth.worktime ? this.timetracking.convertTime(this.timeTrackingMonth.worktime) : '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: this.timeTrackingMonth.breaktime ? this.timetracking.convertTime(this.timeTrackingMonth.breaktime) : '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: this.timeTrackingMonth.absenttime ? this.timetracking.convertTime(this.timeTrackingMonth.absenttime) : '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: this.timeTrackingMonth.needed ? this.timetracking.convertTime(this.timeTrackingMonth.needed) : '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: this.timeTrackingMonth.total ? this.timetracking.convertTime(this.timeTrackingMonth.total) : '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      },
      {
        content: '',
        styles: {
          fontSize: 10
        },
        colSpan: 1
      }
    ]


    let doc = new jsPDF();
    const currentDate = moment()
    const currentMonth = moment(this.filter.date).locale('de').format('MMMM yyyy')

    doc.text('Zeiterfassung von ' + this.user.name, 15, 14);
    doc.setFontSize(12)
    doc.text(currentMonth, 15, 21)
    doc.setFontSize(8)
    doc.text('Erstellt am: ' + currentDate.format('DD.MM.yyyy'), 165, 21)
    doc.setFontSize(8)


    autoTable(doc, {
      head: [header],
      body: exportJSON,
      margin: {top: 30},
      headStyles: {
        fillColor: '#ffe2b9',
        textColor: '#000000'
      },
      foot: [footer],
      footStyles : {
        fillColor: '#ffe2b9',
        textColor: '#000000'
      }
    })
    doc.setTextColor('#5e5e5e');
    doc.text('Erstellt mit Hausmeisterapp.com', 15, 290)

    doc.save('Zeiterfassung ' + this.user.name + ' ' + currentMonth + '.pdf')
  }

  protected readonly now = now;
  protected readonly environment = environment;
}
