import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {Company, Contact, Customer, Object, Room, ServiceCatalog, WorkLog} from '../../../../../../database-models';
import {AuthenticationService} from "../../services/authentication.service";
import {EditObjectRoomComponent} from "../../../objects/object-room/edit-object-room/edit-object-room.component";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-object-customer-select',
  templateUrl: './object-customer-select.component.html',
  styleUrls: ['./object-customer-select.component.css']
})
export class ObjectCustomerSelectComponent implements OnInit {

  @Input()
  public allowClear: boolean = false;

  @Input()
  public roomSelect: boolean = false;

  @Input()
  public multiple: boolean = false;

  @Input()
  public hideLabel: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public optional: boolean = false;

  @Input()
  public worklog: WorkLog;

  get reference(): Object | Contact | Object[] | Contact[] {
    return this._reference;
  }

  @Input()
  set reference(value: Object | Contact | Object[] | Contact[]) {
    this._reference = value;
    this.getRooms();
  }

  private _reference: Object | Contact | Object[] | Contact[];

  @Input()
  public room: Room;

  public company: Company;

  @Output()
  public referenceChange: EventEmitter<Object | Contact | Object[] | Contact[]> = new EventEmitter<Object | Contact>();

  @Output()
  public roomChange: EventEmitter<Room> = new EventEmitter<Room>();

  public rooms: Room[];

  @Input()
  public objects: Object[];

  @Input()
  public contacts: Contact[];


  public serviceCatalogs: ServiceCatalog[];
  public customersLoading = true;

  constructor(
      public api: ApiService,
      private message: NzMessageService,
      public authenticationService: AuthenticationService,
      private modalService: NzModalService,
  ) {
  }

  ngOnInit(): void {
    if (!this.objects) {
      this.getObjects();
    }
    if (!this.contacts) {
      this.getContacts();
    }

    this.authenticationService.currentUser.subscribe(user => {
      this.company = user?.company;
    });
  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objects = objects;
      this.getRooms();
      // this.initForm();
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public getContacts(): void {
    this.api.getContacts().subscribe((customers: Contact[] | any) => {
      this.contacts = customers;
      this.customersLoading = false;
    }, onerror => {
      console.log(onerror);
      this.message.error('Fehler beim Laden der Daten');
    });
  }

  public select(selected: Object | Contact | Object[] | Contact[]): void {
    this.rooms = undefined;
    this.room = undefined;
    this.referenceChange.emit(this._reference);
    this.getRooms();
    this.getServiceCatalogs();
  }

  public getRooms(): void {
    if (!Array.isArray(this._reference)) {
      if (this._reference?.member === 'Object' && this.roomSelect) {
        this.api.getRoomsByObject(this._reference.id).subscribe(value => {
          this.rooms = value;
        });
      }
    }
  };

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getServiceCatalogs(): void {
    if (!Array.isArray(this._reference)) {
      if (this._reference?.member === 'Object') {
        this.api.getCatalogbyObject(this._reference.id).subscribe((serv: ServiceCatalog[]) => {
          this.serviceCatalogs = serv;
          console.log(serv)
        })
      }
      // } else {
      //   this.api.getAllServiceCatalog().subscribe((allServ: ServiceCatalog[]) => {
      //     this.allServiceCatalogs = allServ;
      //   })
      // }
    }
  }

  public addRoom(): void {
    const newObjectModal = this.modalService.create({
      nzContent: EditObjectRoomComponent,
      nzComponentParams: {
        // @ts-ignore
        objectId: this._reference.id,
        title: 'Raum erstellen'
      },
      nzWidth: 800,
      nzFooter: null,
    });

    newObjectModal.afterClose.subscribe((data: any) => {
      this.rooms.push(data);
      this.room = data;
      this.roomChange.emit(data);
    });
  }
}
