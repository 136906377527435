import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  Contact,
  News,
  Object, Room, ServiceCatalogItem,
  Task,
  Team,
  Ticket,
  User, Wasteplan,
  WorkLog,
} from '../../../../../../../database-models';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import * as moment from 'moment';
import * as uuid from 'uuid';
import Inputmask from 'inputmask';
import {MbscCalendarEvent} from '@mobiscroll/angular';

// setup the Mobiscroll Moment plugin
import {momentTimezone} from '@mobiscroll/angular';
import {AuthenticationService} from '../../../services/authentication.service';
import {group} from '@angular/animations';
import {from} from 'rxjs';
import {ServicecatalogChecklistComponent} from '../../servicecatalog-checklist/servicecatalog-checklist.component';
import {NzTableComponent} from 'ng-zorro-antd/table';
import {Router} from '@angular/router';
import {TicketService} from '../../../services/ticket.service';
import {EditWorklogComponent} from '../../edit-worklog/edit-worklog.component';
import {GlobalSearchComponent} from '../../global-search/global-search/global-search.component';
import {color} from 'chart.js/helpers';
import {WorklogService} from "../../../services/worklog.service";

momentTimezone.moment = moment;

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss'],
})
export class EditTaskComponent implements OnInit, AfterViewInit {

  @ViewChild('globalSearchTICKETComponent')
  public globalSearchTICKETComponent: GlobalSearchComponent;

  public momentPlugin = momentTimezone;
  public title = 'Aufgabe';
  public loading = false;
  public showTicketSearch = false;

  @Input()
  public task: Task | MbscCalendarEvent;

  @Input()
  public ticket: Ticket;

  public taskForm: FormGroup;

  @Input()
  public isTempId = false;

  @Input() public wasteplan: Wasteplan;

  public serviceTemplates: string[] = [];
  public users: User[];
  public teams: Team[];
  public worklogs: WorkLog[] = [];
  public reference: Object | Contact;
  public timeType: 'allDay' | 'backlog' | 'timeRange' = 'timeRange';

  public taskDeleted = false;
  public isRecurring = false;
  public isTouched = false;
  public disabledUUIDS: string[];

  public availableColors = [
    {
      color: '#5c83b4',
      name: 'Blau',
    },
    {
      color: '#b94242',
      name: 'Rot',
    },
    {
      color: '#8800b4',
      name: 'Lila',
    },
    {
      color: '#5aad47',
      name: 'Grün',
    },
    {
      color: '#00a49f',
      name: 'Türkis',
    },
    {
      color: '#f8cd53',
      name: 'Gelb',
    },
    {
      color: '#f55a00',
      name: 'Orange',
    },
    {
      color: '#00ff48',
      name: 'Gras-Grün',
    },
    {
      color: '#8f8f8f',
      name: 'Grau',
    },
    {
      color: '#7e4900',
      name: 'Braun',
    },
    {
      color: '#000000',
      name: 'Schwarz',
    },

  ];

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    public authenticationService: AuthenticationService,
    private modalService: NzModalService,
    private ticketService: TicketService,
    @Optional() private modal: NzModalRef,
    public router: Router,
    public worklogService: WorklogService,
  ) {
  }

  @ViewChildren('dateTimeMask') dateTimeMask: QueryList<ElementRef>;

  public setMasks(): void {
    for (let mask of this.dateTimeMask.toArray()) {
      Inputmask('datetime', {
        inputFormat: this.timeType === 'allDay' ? 'dd.mm.yyyy' : 'dd.mm.yyyy HH:MM',
        placeholder: this.timeType === 'allDay' ? 'dd.mm.yyyy' : 'dd.mm.yyyy 00:00',
        alias: 'datetime',

        clearMaskOnLostFocus: false,
        isComplete: function (buffer, opts) {

        }
      }).mask(mask.nativeElement);
    }

  }

  ngAfterViewInit(): void {
    this.setMasks();
  }

  public ngOnInit() {
    this.getServiceTemplates();
    this.getUsers();
    this.getTeams();
    if (!this.task) {
      this.title = 'Aufgabe anlegen';

      this.task = {
        start: moment().toISOString(),
        end: moment().add(1, 'hour').toISOString(),
        backlog: false,
        timezone: 'Europe/Berlin',
        worklogneeded: false
      } as Task;

      // If Ticket is set, preselect some information
      if (this.ticket) {
        this.task.ticket_id = this.ticket.id;
        this.task.title = this.ticket.name;
        this.task.description = this.ticket.description?.replace(/<[^>]*>/g, '');
        this.task.room = this.ticket.room;
        if (this.ticket.object) {
          this.task.object_id = this.ticket.object.id;
          this.task.object = this.ticket.object;
        }
        if (this.ticket.contact) {
          this.task.contact_id = this.ticket.contact.id;
          this.task.contact = this.ticket.contact;
        }
        if (this.ticket.employee) {
          this.task.user_id = this.ticket.employee.id;
          this.task.user = this.ticket.employee;
        }
      } else if (this.wasteplan) {
        console.log(this.wasteplan);
        this.task.title = this.wasteplan.title;
        if (this.wasteplan.description) {
          this.task.description = this.wasteplan.description;
        }
        this.task.color = this.wasteplan.color;
        this.task.allDay = true;
        this.timeType = 'allDay';
        this.task.start = this.wasteplan.time;
        this.task.end = this.wasteplan.time;
        this.task.object_id = this.wasteplan.object_id;
        this.task.object = this.wasteplan.object;
      }
    } else {

      this.getWorklogs();
      if (this.task.ticket_id) {
        this.getTicket(this.task.ticket_id);
      }

      if (this.task.backlog) {
        this.timeType = 'backlog';
      } else if (this.task.allDay) {
        this.timeType = 'allDay';
      } else {
        this.timeType = 'timeRange';
      }
    }

    this.reference = this.task.object || this.task.contact;
    if (!this.task.resource) {
      this.task.resource = this.task.user?.id || this.task.team?.id;
    }
    console.log(this.task.resource);
    if (this.task.recurring) {
      this.isRecurring = true;
      this.title = 'Wiederkehrende Aufgabe';
    }

    if (this.task.wasteplan?.id) {
      this.title = 'Aufgabe aus Abfuhrplan';
    }

    this.taskForm = this.formBuilder.group(
      {
        title: [
          this.task.title === 'Neue Aufgabe' ? '' : this.task.title,
          [Validators.required],
        ],
        description: [this.task.description],
        resource: [this.task.resource, [Validators.required]],
        start: [this.task.start],
        end: [this.task.end],
        color: [this.task.color],
        worklogneeded: [this.task.worklogneeded],
      },
      {
        validators: [this.dateLessThan('start', 'end'), this.validateBacklog()],
      }
    );
  }

  public autoSetEndDate(event: any): void {
    const start = this.taskForm.get('start').value;
    let newValue = event.value;
    let beforeValue = start;
    const end = this.taskForm.get('end').value;

    const diffValue = moment(end).diff(moment(beforeValue));
    console.log(diffValue);
    const newEnd = moment(newValue).add(diffValue);
    this.taskForm.get('end').setValue(newEnd);


  }

  private validateBacklog(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {

      const f = form.get('start').value;
      const t = form.get('end').value;
      if (this.timeType === 'timeRange') {
        if (!f || !t) {
          return {
            dates: 'Start und Ende müssen angegeben werden',
          };
        }
      }
      return null;
    };
  }

  private dateLessThan(from: string, to: string): any {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (this.timeType === 'timeRange') {
        if (moment(f.value) > moment(t.value)) {
          return {
            dates: 'Das Ende darf nicht vor dem Start liegen',
          };
        }
      } else {
        if (moment(f.value).startOf('day') > moment(t.value).startOf('day')) {
          return {
            dates: 'Das Ende darf nicht vor dem Start liegen',
          };
        }
      }

      return {};
    };
  }

  public getServiceTemplates(): void {
    this.api.getServiceTemplates().subscribe(
      (serviceTemplates: any[]) => {
        for (const template of serviceTemplates) {
          this.serviceTemplates.push(template.name);
        }
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Service Templates konnten nicht geladen werden.');
      }
    );
  }

  public getUsers(): void {
    this.api.getUsers().subscribe(
      (employees: User[]) => {
        this.users = employees;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Mitarbeiter konnten nicht geladen werden');
      }
    );
  }

  public getTeams(): void {
    this.api.getTeam().subscribe(
      (teams: Team[]) => {
        this.teams = teams;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Teams konnten nicht geladen werden');
      }
    );
  }

  public getWorklogs(): void {
    if (this.task.id) {
      this.api.getTaskWorklogs(this.task.id).subscribe(
        (worklogs: WorkLog[]) => {
          this.worklogs = worklogs;
          if (worklogs && worklogs.length > 0) {
            this.modal.updateConfig({
              nzWidth: '90%'
            });
          }
          // this.initForm();
        },
        (onerror) => {
          console.log(onerror);
          this.message.error('Arbeitsprotokolle konnten nicht geladen werden');
        }
      );
    }
  }

  public referenceSelected(value: Object | Contact): void {
    switch (value.member) {
      case 'Object':
        this.task.object_id = value.id;
        this.task.object = value;
        this.task.contact_id = null;
        this.task.contact = null;
        break;
      case 'Contact':
        this.task.contact_id = value.id;
        this.task.contact = value;
        this.task.object_id = null;
        this.task.object = null;
        this.task.room_id = null;
        break;
    }
  }

  public save(closeOnSuccess: boolean = true): void {
    this.loading = true;
    const toSave: Task = this.taskForm.getRawValue();
    toSave.id = this.task.id ? this.task.id.toString() : undefined;

    if (this.ticket) {
      toSave.ticket_id = this.ticket.id;
    }

    if (this.isRecurring) {
      toSave.recurring = this.task.recurring;
      toSave.done = false;
    } else {
      toSave.recurring = toSave.recurringException = null;
    }

    switch (this.timeType) {
      case 'backlog':
        toSave.start = null;
        toSave.end = null;
        toSave.recurring = null;
        toSave.recurringException = null;
        toSave.backlog = true;
        break;
      case 'allDay':
        toSave.allDay = true;
        toSave.backlog = false;
        break;
      case 'timeRange':
        toSave.backlog = false;
        break;
    }

    toSave.contact_id = this.task.contact_id;
    toSave.object_id = this.task.object_id;
    toSave.room_id = this.task.room?.id;
    toSave.selectedServices = this.task.selectedServices;

    this.api.setTask(toSave).subscribe(
      (task: Task) => {
        this.message.success('Erfolgreich gespeichert');

        let returnTask = toSave;
        if (task.id) {
          returnTask = task;
        }

        returnTask.object = this.task.object;
        returnTask.contact = this.task.contact;
        returnTask.room = this.task.room;

        if (returnTask.user_id) {
          returnTask.user = this.users.find(
            (value) => value.id === returnTask.user_id
          );
          returnTask.resource = returnTask.user_id;
        }
        if (returnTask.team_id) {
          returnTask.team = this.teams.find(
            (value) => value.id === returnTask.team_id
          );
          returnTask.resource = returnTask.team_id;
        }
        if (returnTask.resource) {
          returnTask.team = this.teams.find(
            (value) => value.id === returnTask.resource
          );
          returnTask.user = this.users.find(
            (value) => value.id === returnTask.resource
          );
        }

        this.task = returnTask;
        this.loading = false;
        if (closeOnSuccess) {
          this.modal.triggerOk();
        }

      },
      (error) => {
        this.loading = false;
      }
    );
  }

  public cancel(): void {
    this.modalService.confirm({
      nzTitle: '<i>Bearbeiten abbrechen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
      nzOnOk: () => this.modal.triggerCancel()
    });
  }

  public deleteTask(): void {
    this.modalService.warning({
      nzTitle: '<i>Aufgabe löschen</i>',
      nzContent:
        'Sind Sie sich sicher, dass Sie die Aufgabe löschen möchten? Alle Daten gehen verloren.' + (this.task.recurring ? '<br><br>Achtung: Dies beinhaltet auch alle zugehörigen, wiederkehrenden Aufgaben!' : ''),
      nzOnOk: () => this.delete(),
      nzAutofocus: 'ok',
      nzCancelText: 'Abbrechen',
    });
  }

  private delete(): void {
    this.loading = true;
    this.api.deleteTask(this.task.id).subscribe(
      (news: News) => {
        this.message.success('Aufgabe erfolgreich gelöscht.');
        this.taskDeleted = true;
        this.loading = false;
        this.modal.triggerOk();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getTicket(id: string): void {
    this.api.getTicket(id).subscribe(
      (ticket: Ticket) => {
        this.ticket = ticket;
      },
      (onerror) => {
        console.log(onerror);
        this.message.error('Ticket konnte nicht geladen werden');
      }
    );
  }

  public duplicate(): void {
    let copyTask: Task | MbscCalendarEvent = JSON.parse(
      JSON.stringify(this.task)
    );
    copyTask.id = null;
    copyTask.title += ' (Kopie)';
    copyTask.recurringDone = [];
    const editTaskModal = this.modalService.create({
      nzContent: EditTaskComponent,
      nzComponentParams: {
        task: copyTask,
      },
      nzWidth: '1100px',
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzOnCancel: (instance) => {
      },
      nzOnOk: (instance) => {
      },
    });
  }

  public openCatalog(): void {
    const modal = this.modalService.create({
      nzContent: ServicecatalogChecklistComponent,
      nzComponentParams: {
        objId: this.task?.object_id
      },
      nzWidth: '600px',
      nzFooter: null,
      nzOnCancel: (instance) => {
      },
      nzOnOk: (instance) => {
        location.reload();
      }
    });
    modal.afterClose.subscribe(result => {
      let serviceItems: ServiceCatalogItem[] = result.flat;
      if (serviceItems) {
        if (!this.task.selectedServices) {
          this.task.selectedServices = [];
        }
        for (let ser of serviceItems) {
          this.task.selectedServices.push(ser);
        }

      }
    });
  }

  public addOwnService(): void {
    if (!this.task.selectedServices) {
      this.task.selectedServices = [];
    }
    const newid = uuid.v4();
    this.task.selectedServices = [
      ...this.task.selectedServices,
      {
        id: newid,
        name: ''
      }
    ];
  }

  public navigateToTicketPage(ticketId: string) {
    window.open('/tickets/' + ticketId, '_blank');
  }

  public deleteCservice(index: number): void {
    this.modalService.warning({
      nzTitle: '<i>Eintrag löschen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Eintrag löschen möchten?',
      nzOnOk: () => this.deleteCompletedService(index),
      nzCancelText: 'Abbrechen',
    });
  }

  public deleteCompletedService(index: number): void {
    this.task.selectedServices.splice(index, 1);
  }

  public linkTicket(ticket: Ticket): void {
    this.task.ticket = this.ticket = ticket;
    this.task.ticket_id = ticket.id;

    const resourceId = this.taskForm.get('resource').value;
    if (resourceId) {
      const user: User = this.users.find(user => user.id === resourceId);
      if (user) {
        ticket.employee_id = user.id;
        ticket.employee = user;
      }
    }

    this.save(false);
  }

  public createTicket(): void {
    let ticket = {
      name: this.taskForm.get('title').value,
      description: this.taskForm.get('description').value,
      object: this.task.object,
      contact: this.task.contact,
      room: this.task.room
    } as Ticket;

    const resourceId = this.taskForm.get('resource').value;

    if (resourceId) {
      const user: User = this.users.find(user => user.id === resourceId);
      if (user) {
        ticket.employee_id = user.id;
        ticket.employee = user;
      }
    }


    this.ticketService.newTicketModal(ticket).then(ticket => {
      this.task.ticket = this.ticket = ticket;
      this.task.ticket_id = ticket.id;
      this.showTicketSearch = false;
      this.save(false);
    });
  }

  public editWorklog(worklog: WorkLog): void {
    const newObjectModal = this.modalService.create({
      nzContent: EditWorklogComponent,
      nzComponentParams: {
        worklogId: worklog.id
      },
      nzWidth: '1500px',
      nzFooter: null,
      nzMaskClosable: false,
    });

    newObjectModal.afterClose.subscribe((data: any) => {
    });
  }

  public toggleTicketSearch() {
    this.showTicketSearch = !this.showTicketSearch;
    setTimeout(() => {
      this.globalSearchTICKETComponent.searchFocus();
    }, 100);
  }

  public handleShowSearchChange(value: boolean): void {
    this.showTicketSearch = value;
  }

  public deleteTaskTicketRelation(task: any): void {
    this.showTicketSearch = false;
    this.modalService.warning({
      nzTitle: '<i>Verknüpfung löschen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diese Verknüpfung löschen möchten?',
      nzOnOk: () => this.api.deleteTaskTicketRelation(task.id).subscribe(value => {
        this.task.ticket = this.ticket = null;
        this.task.ticket_id = null;
        this.showTicketSearch = false;
        this.save(false);
      }),
      nzCancelText: 'Abbrechen',
    });
  }

  public newWorklog(): void {
    this.worklogService.newWorklog({task: this.task}).then(res => {
        this.getWorklogs();
    })
  }

  protected readonly moment = moment;
  protected readonly JSON = JSON;
  protected readonly color = color;
}
